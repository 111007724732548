<template>
    <div>
        <p
            class="text-h6 font-weight-bold"
            style="line-height:28px;"
        >
            <span class="primary--text">계약 기간</span><br/>
        </p>

        <v-img
            style="position:absolute; top:80px; right:40px;"
            width="80"
            src="@/assets/logo/default.png"
        ></v-img>

        <!-- 간편선택 -->
        <p
            class="text-body-2 mt-6 mb-2 font-weight-medium"
        >
            간편선택
        </p>
        <v-btn
            block
            outlined
            class="rounded-lg"
            style="background:white;"
            :color="term == '오늘만'? 'primary':'grey lighten-1'"
            @click="simpleSelect('오늘만')"
        >
            오늘만
        </v-btn>

        <div class="d-flex justify-space-between mt-2">
            <v-btn
                width="32%"
                outlined
                class="rounded-lg"
                style="background:white;"
                :color="term == '1주'? 'primary':'grey lighten-1'"
                @click="simpleSelect('1주')"
            >
                1주
            </v-btn>
            <v-btn
                width="32%"
                outlined
                class="rounded-lg"
                style="background:white;"
                :color="term == '2주'? 'primary':'grey lighten-1'"
                @click="simpleSelect('2주')"
            >
                2주
            </v-btn>
            <v-btn
                width="32%"
                outlined
                class="rounded-lg"
                style="background:white;"
                :color="term == '3주'? 'primary':'grey lighten-1'"
                @click="simpleSelect('3주')"
            >
                3주
            </v-btn>
        </div>

        <div class="d-flex justify-space-between mt-2">
            <v-btn
                width="32%"
                outlined
                class="rounded-lg"
                style="background:white;"
                :color="term == '한달'? 'primary':'grey lighten-1'"
                @click="simpleSelect('한달')"
            >
                한달
            </v-btn>
            <v-btn
                width="32%"
                outlined
                class="rounded-lg"
                style="background:white;"
                :color="term == '두달'? 'primary':'grey lighten-1'"
                @click="simpleSelect('두달')"
            >
                두달
            </v-btn>
            <v-btn
                width="32%"
                outlined
                class="rounded-lg"
                style="background:white;"
                :color="term == '세달'? 'primary':'grey lighten-1'"
                @click="simpleSelect('세달')"
            >
                세달
            </v-btn>
        </div>

        <div class="d-flex justify-space-between mt-2">
            <v-btn
                width="32%"
                outlined
                class="rounded-lg"
                style="background:white;"
                :color="term == '100일'? 'primary':'grey lighten-1'"
                @click="simpleSelect('100일')"
            >
                100일
            </v-btn>
            <v-btn
                width="32%"
                outlined
                class="rounded-lg"
                style="background:white;"
                :color="term == '반년'? 'primary':'grey lighten-1'"
                @click="simpleSelect('반년')"
            >
                반년
            </v-btn>
            <v-btn
                width="32%"
                outlined
                class="rounded-lg"
                style="background:white;"
                :color="term == '1년'? 'primary':'grey lighten-1'"
                @click="simpleSelect('1년')"
            >
                1년
            </v-btn>
        </div>

        <!-- 직접선택 -->
        <p
            class="text-body-2 mt-6 mb-2 font-weight-medium"
        >
            직접선택
        </p>
        <v-date-picker
            class="rounded-10 pb-3"
            locale="ko-KR"
            v-model="term_select"
            range
            full-width
        ></v-date-picker>

        <div
            class="d-flex align-center mt-2"
        >
            <v-text-field
                class="mt-2 rounded-10"
                style="background:white;"
                outlined
                dense
                readonly
                hide-details
                v-model="term_select[0]"
            ></v-text-field>
            <p
                class="mx-3 mb-0 pt-1 font-weight-bold"
            >
                ~
            </p>
            <v-text-field
                class="mt-2 rounded-10"
                style="background:white;"
                outlined
                dense
                readonly
                hide-details
                v-model="term_select[1]"
                append-icon="mdi-date"
            ></v-text-field>
        </div>

        <!-- 다음 -->
        <v-btn
            block
            class="rounded-10 mt-8"
            height="48"
            large
            color="primary"
            dark
            depressed
            @click="submit()"
        >
            다음
        </v-btn>
</div>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
    data: () => ({
        term : "오늘만",
        term_select: [],
    }),

    created(){
        if(this.$store.state.contract.start_date){
            this.term_select[0] = this.$store.state.contract.start_date
        }

        if(this.$store.state.contract.end_date){
            this.term_select[1] = this.$store.state.contract.end_date
        }
    },

    methods: {
        formatDate(date) {
            if (!date) return '';
            const d = new Date(date);
            let month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [year, month, day].join('-');
        },

        ...mapMutations(['contract_update_term']),

        // 간편선택
        simpleSelect(term){
            this.term = term
            let today = new Date()

            if(this.term == "오늘만")
            {
                this.term_select[0] = this.formatDate(today)
                this.term_select[1] = this.formatDate(today)
            }
            else if(this.term == "1주")
            {
                this.term_select[0] = this.formatDate(today)
                this.term_select[1] = this.formatDate(new Date(today.setDate(today.getDate() + 7)))
            }
            else if(this.term == "2주")
            {
                this.term_select[0] = this.formatDate(today)
                this.term_select[1] = this.formatDate(new Date(today.setDate(today.getDate() + 14)))
            }
            else if(this.term == "3주")
            {
                this.term_select[0] = this.formatDate(today)
                this.term_select[1] = this.formatDate(new Date(today.setDate(today.getDate() + 21)))
            }
            else if(this.term == "한달")
            {
                this.term_select[0] = this.formatDate(today)
                this.term_select[1] = this.formatDate(new Date(today.setMonth(today.getMonth() + 1)))
            }
            else if(this.term == "두달")
            {
                this.term_select[0] = this.formatDate(today)
                this.term_select[1] = this.formatDate(new Date(today.setMonth(today.getMonth() + 2)))
            }
            else if(this.term == "세달")
            {
                this.term_select[0] = this.formatDate(today)
                this.term_select[1] = this.formatDate(new Date(today.setMonth(today.getMonth() + 3)))
            }
            else if(this.term == "100일")
            {
                this.term_select[0] = this.formatDate(today)
                this.term_select[1] = this.formatDate(new Date(today.setDate(today.getDate() + 100)))
            }
            else if(this.term == "반년")
            {
                this.term_select[0] = this.formatDate(today)
                this.term_select[1] = this.formatDate(new Date(today.setMonth(today.getMonth() + 6)))
            }
            else if(this.term == "1년")
            {
                this.term_select[0] = this.formatDate(today)
                this.term_select[1] = this.formatDate(new Date(today.setFullYear(today.getFullYear() + 1)))
            }
        },
        
        // 날짜 형식 변경 (YYYY-MM-DD)
        formatDate(date) {
            const year = date.getFullYear()
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const day = date.getDate().toString().padStart(2, '0')
            return `${year}-${month}-${day}`
        },

        // 다음
        submit(){
            if(!this.term_select[0] || !this.term_select[1]){
                this.$globalSnackbar.show("계약 기간을 입력해주세요")
            }else{
                let payload = {
                    start_date: this.term_select[0],
                    end_date: this.term_select[1]
                }
                this.contract_update_term(payload)
                this.$emit('next')
            }
        }
    }
}
</script>
<style scoped>
::v-deep .v-date-picker-table td {
    /* height:40px !important; */
    padding: 8px 0px;
}
</style>