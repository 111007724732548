<template>
    <div
        style="background:white; height:100%;"
    >
        <div
            style="position:relative; background:#f4f2f1; width:100%; max-width:440px; height:100%; margin:0 auto; border-left:1px solid #eaeaea; border-right:1px solid #eaeaea; box-shadow:0px 2px 6px 2px #f1f1f1;"
        >
            <!-- 본문 -->
            <router-view
                style="width:100%; height:100%;"
                :style="deviceStyle"
                :key="$route.fullPath"
            ></router-view>

            <!-- 하단 -->
            <Footer
                style="position:fixed; bottom:0px; left:0; right:0; width:100%; max-width:440px; margin:0 auto;"
            />
        </div>
    </div>
</template>

<script>
import Footer from './Footer'

export default {
    components: {
        Footer
    },

    data: () => ({
        deviceStyle: {}
    }),

    mounted() {
        this.detectDevice()
    },

    methods: {
        detectDevice() {
            const ua = navigator.userAgent

            if (/android/i.test(ua)) {
                this.deviceStyle = { 'padding-bottom': '140px' }
            } else if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
                this.deviceStyle = {}
            } else {
                this.deviceStyle = {}
            }
        }
    }
}
</script>