<template>
    <div>
        <!-- 상단 -->
        <div>
            <!-- 제목 -->
            <p
                class="text-h6 font-weight-bold mb-16"
                style="line-height:28px;"
            >
                <span class="primary--text">계약서</span><br/>
            </p>

            <!-- 로고 -->
            <v-img
                style="position:absolute; top:80px; right:40px;"
                width="80"
                src="@/assets/logo/default.png"
            ></v-img>
        </div>

        <v-sheet
            class="mb-8 rounded-10 pt-2 pb-4 px-4"
        >
            <p class="mt-4 mb-4 text-subtitle-1 font-weight-bold" style="line-height:20px;">
                {{top}}
            </p>
            
            <div
                v-for="(item, index) in list" :key="index"
            >
                <p
                    class="mt-10 mb-4 text-subtitle-1 font-weight-bold grey--text text--darken-1"
                    style="line-height:20px;"
                >
                    제{{index+1}}조 ({{item.title}})
                </p>
                <p
                    style="white-space: pre-line;"
                    class="mx-2 font-weight-medium grey--text text--darken-1"
                >
                    {{item.content}}
                </p>
            </div>
        </v-sheet>

        <!-- 계약서 수정하기 -->
        <v-btn
            block
            class="rounded-10 primary--text  mb-4"
            height="48"
            large
            color="grey lighten-2"
            dark
            depressed
            @click="bottom_sheet.modify = true"
        >
            계약서 수정하기
        </v-btn>

        <!-- 다음 -->
        <v-btn
            block
            class="rounded-10"
            height="48"
            large
            color="primary"
            dark
            depressed
            @click="submit()"
        >
            다음
        </v-btn>

        <!-- 계약서 수정하기 (bottom-sheet)  -->
        <v-bottom-sheet
            inset
            v-model="bottom_sheet.modify"
            scrollable
        >
            <v-sheet
                class="elevation-0 pt-10 pb-14 px-4"
                style="border-top-left-radius:30px; border-top-right-radius:30px; position:relative; overflow:scroll;"
            >
                <v-icon
                    style="position:absolute; top:16px; left:12px;"
                    size="32"
                    color="grey"
                    @click="bottom_sheet.modify = false"
                >
                    mdi-chevron-left
                </v-icon>
                <p
                    class="text-h6 mb-2 primary--text mt-4 font-weight-bold"
                >
                    계약서 수정하기
                </p>
                <v-img
                    style="position:absolute; top:50px; right:40px;"
                    width="80"
                    src="@/assets/logo/default.png"
                ></v-img>

                <v-textarea
                    outlined
                    style="background:white;"
                    class="rounded-lg"
                    hide-details
                    v-model="top"
                ></v-textarea>

                <div
                    v-for="(item, index) in list" :key="index"
                >
                    <p
                        class="text-body-2 mt-6 mb-2 font-weight-medium"
                    >
                        제{{index+1}}조
                    </p>
                    <v-text-field
                        outlined
                        style="background:white;"
                        class="rounded-lg mb-1"
                        hide-details
                        dense
                        v-model="item.title"
                    ></v-text-field>
                    <v-textarea
                        outlined
                        style="background:white;"
                        class="rounded-lg"
                        hide-details
                        v-model="item.content"
                    ></v-textarea>
                </div>

                <br />
                <v-btn
                    class="mt-4 primary--text"
                    large
                    depressed
                    dark
                    color="grey lighten-2"
                    block
                    @click="add()"
                >
                    조항 추가하기
                </v-btn>
                <v-btn
                    class="mt-4"
                    large
                    depressed
                    dark
                    color="primary"
                    block
                    @click="updateSubmit()"
                >
                    수정 완료
                </v-btn>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
    data: () => ({
        top: [],
        list: [],

        bottom_sheet: {
            modify: false
        }
    }),

    mounted(){
        let p_list = this.$store.state.contract.duty_list
        let v_list = this.$store.state.contract.duty_list

        if(this.$store.state.contract.duty_list.length){
            p_list = p_list.map((e, index) => {
                return this.convert_circle(index+1) + " " + e.p1 + " " + e.p2 + " " + e.p3 + " " + e.p4 + " " + e.p_memo
            })

            v_list = v_list.map((e, index) => {
                console.log(e.v_memo_2)
                return "3조 "
                + this.convert_circle(index+1)+ "항 위반 시\n "
                + (e.v_memo ==  ""? "":(this.convert_circle(index+1) + " " + e.v1 + " " + e.v2 + " " + e.v3 + " " + e.v4 + " " + e.v_memo) + "\n")
                + (e.v_memo_2 ==  ""? "":(this.convert_circle(index+2) + " " + e.v1_2 + " " + e.v2_2 + " " + e.v3_2 + " " + e.v4_2 + " " + e.v_memo_2))
            })
        }else{
            p_list = "①\n②\n③"
            v_list = "①\n②\n③"
        }

        if(!this.$store.state.contract.sheet.top && !this.$store.state.contract.sheet.list.length){
            this.top = `${this.$store.state.contract.my.name}(${this.$store.state.contract.my.birth.slice(0,4)}. ${this.$store.state.contract.my.birth.slice(4,6)}. ${this.$store.state.contract.my.birth.slice(6,8)}.생, 이하 '${this.$store.state.contract.my.love_name}'라 한다)와 ${this.$store.state.contract.partner.name}(${this.$store.state.contract.partner.birth.slice(0,4)}. ${this.$store.state.contract.partner.birth.slice(4,6)}. ${this.$store.state.contract.partner.birth.slice(6,8)}생, 이하 '${this.$store.state.contract.partner.love_name}'이라 한다)은 ${this.$store.state.contract.love_start.slice(0,4)}. ${this.$store.state.contract.love_start.slice(4,6)}. ${this.$store.state.contract.love_start.slice(6,8)}.자 시작한 연애와 관련하여 아래와 같이 계약을 체결한다.`

            this.list = [
                {
                    title: "계약의 목적",
                    content: `본 계약은 ${this.$store.state.contract.my.love_name}와 ${this.$store.state.contract.partner.love_name}의 원만한 연인관계를 유지하기 위하여 상호 간에 지켜야 할 의무 사항을 정함을 목적으로 한다.`
                },
                {
                    title: "계약 기간",
                    content: 
                    `① 본 계약의 기간은 ${this.$store.state.contract.start_date.slice(0,4)}.${this.$store.state.contract.start_date.slice(5,7)}.${this.$store.state.contract.start_date.slice(8,10)}.로 부터${this.$store.state.contract.end_date.slice(0,4)}.${this.$store.state.contract.end_date.slice(5,7)}.${this.$store.state.contract.end_date.slice(8,10)}.까지로 한다\n② 제1항 기간 만료일로부터 3일 전까지 일방 당사자가 상대방에게 계약 종료의 의사표시를 통지하지 아니하면 본 계약은 동일한 내용으로 갱신된다.`
                },
                {
                    title: "의무 사항",
                    content: this.$store.state.contract.duty_list.length? p_list.join("\n") : p_list
                },
                {
                    title: "위반 시 조치",
                    content: this.$store.state.contract.duty_list.length? v_list.join("\n") : v_list
                },
                {
                    title: "불가항력",
                    content: "계약 당사자가 천재지변 기타 불가항력 사유로 제3조 내용을 위반한 경우에는 제4조에 따른 책임을 지지 아니한다."
                },
                {
                    title: "계약의 해지",
                    content: "계약 당사자는 각 호의 해당하는 경우 상대방에 대한 통지로써 본 계약을 해지할 수 있다.\n 1. 상대방이 정당한 이유 없이 본 계약 상의 의무를 이행하지 아니하는 경우\n 2. 기타 사회 통념 상 본 계약을 유지하기 어렵다고 인정되는 경우"
                },
                {
                    title: "계약의 변경",
                    content: "양 당사자의 합의에 따라 본 계약의 내용을 변경할 수 있다."
                },
                {
                    title: "신의성실",
                    content: "양 당사자는 신의에 따라 성실하게 본 계약 상 의무를 이행한다."
                },
                {
                    title: "해석",
                    content: "이 계약에 명기되지 않은 사항 및 계약의 해석 상 이의가 있을 때에는 상호협의하여 결정한다."
                }
            ]
        }else{
            this.top = this.$store.state.contract.sheet.top 
            this.list = this.$store.state.contract.sheet.list 
        }
    },

    methods: {
        ...mapMutations(['contract_update_sheet']),

        // 동그라미 숫자로 변경
        convert_circle(index){
            if(index == 1) return "①"
            if(index == 2) return "②"
            if(index == 3) return "③"
            if(index == 4) return "④"
            if(index == 5) return "⑤"
            if(index == 6) return "⑦"
            if(index == 7) return "⑦"
            if(index == 8) return "⑧"
            if(index == 9) return "⑨"
            if(index == 10) return "⑩"
            if(index == 11) return "⑪"
            if(index == 12) return "⑫"
            if(index == 13) return "⑬"
            if(index == 14) return "⑭"
            if(index == 15) return "⑮"
        },

        // 수정 완료
        updateSubmit(){
            this.bottom_sheet.modify = false
        },

        // 조항 추가하기
        add(){
            this.list.push({title: "", content: ""})
        },

        // 다음
        submit(){
            // 비었을 경우
            if(!this.top || !this.list.length){
                this.$globalSnackbar.show("계약서 내용을 채워주세요")
            }
            // 정상일 경우
            else{
                let payload = {
                    top: this.top,
                    list: this.list
                }
                this.contract_update_sheet(payload)
                this.$emit('next')
            }
        }
    }
}
</script>