<template>
    <v-sheet
        style="margin:0 auto; position:relative; background:#f4f2f1;"
        class="px-2"
        :width="$vuetify.breakpoint.mobile? '100%':'440px'"
    >
        <!-- 상단 -->
        <v-sheet
            color="#f4f2f1"
            class="px-4 pb-0"
        >
            <!-- 제목 -->
            <p
                class="text-h6 primary--text font-weight-bold mb-1 pt-6"
                style="line-height:28px;"
            >
                우리는 커플이다
            </p>

            <!-- 부제목 -->
            <p
                class="text-subtitle-1 font-weight-bold grey--text text--darken-1"
                style="line-height:28px;"
            >
                오늘의 연애운
            </p>

            <!-- 로고 -->
            <v-img
                style="position:absolute; top:20px; right:40px;"
                width="60"
                src="@/assets/logo/default.png"
            ></v-img>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet class="pa-4 rounded-10">
            <!-- 이미지 -->
            <v-img
                width="36%"
                class="mx-auto mt-6 mb-2"
                src="@/assets/ai/fortune.png"
            ></v-img>

            <!-- 상단 -->
            <p
                class="text-h6 text-center mb-0"
            >
                오늘의 연애운
            </p>

            <p
                class="text-body-2 text-center primary--text"
            >
                오늘의 운세를 알아보자
            </p>

            <!-- 내용 -->
            <v-divider class="mb-2 mx-2"></v-divider>

            <!-- 답변 전 -->
            <div
                v-if="!answered && !loading"
            >
                <!-- 입력 -->
                <v-text-field
                    style="width:200px;"
                    class="rounded-10 mx-auto mt-6"
                    v-model="name"
                    outlined
                    dense
                    hide-details
                    placeholder="이름"
                ></v-text-field>

                <!-- 생년월일 -->
                <v-text-field
                    style="width:200px;"
                    class="rounded-10 mx-auto mt-3"
                    v-model="birth"
                    outlined
                    dense
                    hide-details
                    type="number"
                    placeholder="생년월일(예:20010204)"
                ></v-text-field>

                <v-btn
                    class="rounded-10 d-flex mx-auto mt-6 mb-4"
                    color="primary"
                    width="160"
                    dark
                    depressed
                    @click="callOpenAI()"
                >
                    운세 확인하기
                </v-btn>
            </div>

            <!-- 답변 후 -->
            <div
                v-if="answered && !loading"
            >
                <p
                    class="text-subtitle-2 mt-4 mx-8"
                >
                    {{response}}
                </p>
            </div>

            <!-- 로딩 -->
            <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
                class="d-flex mt-14 mb-12 mx-auto"
                size="60"
            ></v-progress-circular>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        name: "",
        birth: "",
        apiKey: 'sk-proj-XAXWwzMSlSzeKCYZvNPJT3BlbkFJxhdW1rGp6Do6Sej0JvQS',
        prompt: '',
        response: '',
        answered: false,
        loading: false,
    }),

    mounted(){

    },

    methods: {
        async callOpenAI() {
            if(this.name == "" || this.birth == ""){
                this.$globalSnackbar.show("입력란이 비어있습니다")
                return
            }

            // AI 카운팅 불러오기
            this.ai_counting()

            this.loading = true

            this.prompt = this.name + " 생년월일" + this.birth + "인 사람의 오늘의 연애운을 120자 이내로 설명해줘"


            try {
                const result = await this.$http.post('https://api.openai.com/v1/chat/completions', {
                    model: 'gpt-3.5-turbo',
                    messages: [{ role: 'user', content: this.prompt }],
                    temperature: 0,
                    n: 1
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.apiKey}`
                    }
                })
                console.log(result)
                this.loading = false
                this.response = result.data.choices[0].message.content
                this.answered = true
            } catch (error) {
                console.error('Error calling OpenAI API:', error)
            }
        },

        // AI 카운팅 불러오기
        ai_counting(){
            this.$http.post('/api/simple/ai_count/update', {
                params: {
                    ai_category: "ai_fortune"
                }
            }).then((res) => {
                console.log(res)
            })
        },
    }
}
</script> 