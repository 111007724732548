<template>
    <div>
        <!-- 로그인 상태 -->
        <v-sheet
            v-if="$store.state.sweetdeal_user.is_logined"
            color="#f4f2f1"
            class="px-4 pb-16"
            style="position:relative;"
        >
            <!-- # 프로필 이미지 -->
            <v-img
                v-if="!alarm_unwatched"
                class="rounded-circle"
                style="position:absolute; top:40px; right:28px; width:40px; height:40px; cursor:pointer;"
                :src="'/upload/user/profile/' + $store.state.sweetdeal_user.profile_image"
                @click="$router.push('/mypage/dashboard')"
            ></v-img>

            <!-- 알람 내역이 있을때 -->
            <v-badge
                v-if="alarm_unwatched"
                style="position:absolute; top:50px; right:18px; width:40px; height:40px; cursor:pointer;"
                color="error"
                :content="alarm_unwatched"
                offset-x="20"
                offset-y="4"
            >
                <v-btn
                    width="20"
                    height="20"
                    fab
                    text
                    depressed
                    class="custom-btn red"
                    v-ripple="false"
                >
                    <!-- # 프로필 이미지 -->
                    <v-img
                        style="width:40px; height:40px;"
                        class="rounded-circle"
                        :src="'/upload/user/profile/' + $store.state.sweetdeal_user.profile_image"
                        @click="$router.push('/mypage/dashboard')"
                    ></v-img>
                </v-btn>
            </v-badge>

            <!-- 제목 -->
            <p
                class="text-h6 primary--text font-weight-bold pt-10 mb-2"
                style="line-height:28px;"
            >
                우리는 커플이다
            </p>

            <!-- 카운팅 -->
            <span class="text-subtitle-2 font-weight-bold alter_black--text text--lighten-2">스윗딜 가입 커플수</span>
            <animated-number
                v-if="!loading"
                class="text-subtitle-1 font-weight-bold primary--text ml-6px mr-2px"
                :value="counting" 
                :formatValue="formatToPrice"
                :duration="2000"
            ></animated-number>
            <span class="text-subtitle-2 font-weight-bold alter_black--text text--lighten-2">명</span>

            <!-- # 메뉴 -->
            <v-sheet
                class="rounded-10 pa-2 mt-4 pt-3"
                style="position:relative; box-shadow:0px 0px 4px 1px #e4e2e1;"
            >
                <!-- 로고 -->
                <v-img
                    style="position:absolute; right:12px; top:8px;"
                    width="16%"
                    src="@/assets/logo/default.png"
                ></v-img>

                <!-- # 연인 계약서 -->
                <div>
                    <!-- # 제목 -->
                    <p
                        class="ml-3 mt-3 font-weight-bold alter_black--text"
                    >
                        연인 계약서
                    </p>

                    <!-- # 메뉴 -->
                    <div
                        class="d-flex mx-2"
                    >
                        <!-- # 작성하기 -->
                        <div
                            style="width:33%; cursor:pointer;"
                            class="d-flex justify-center"
                            @click="$router.push('/contract')"
                        >
                            <div
                                style="width:80px;"
                            >
                                <!-- 아이콘 -->
                                <v-img
                                    class="mx-4"
                                    src="@/assets/home/menu/contract_create.png"
                                ></v-img>

                                <!-- 글자 -->
                                <p
                                    class="text-center text-caption mt-1"
                                    style="letter-spacing: -0.7px !important;"
                                >
                                    작성하기
                                </p>
                            </div>
                        </div>

                        <!-- # 둘러보기 -->
                        <div
                            style="width:33%; cursor:pointer;"
                            class="d-flex justify-center"
                            @click="$router.push('/list')"
                        >
                            <div
                                style="width:80px;"
                            >
                                <!-- 아이콘 -->
                                <v-img
                                    class="mx-4"
                                    src="@/assets/home/menu/contract_read.png"
                                ></v-img>

                                <!-- 글자 -->
                                <p
                                    class="text-center text-caption mt-1"
                                    style="letter-spacing: -0.7px !important;"
                                >
                                    둘러보기
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <v-divider class="mx-2 mt-3 mb-4"></v-divider>

                <!-- # 위시리스트 -->
                <div>
                    <!-- # 제목 -->
                    <p
                        class="ml-3 mt-3 font-weight-bold alter_black--text"
                    >
                        위시리스트
                    </p>

                    <!-- # 메뉴 -->
                    <div
                        class="d-flex mx-2"
                    >
                        <!-- # 작성하기 -->
                        <div
                            style="width:33%; cursor:pointer;"
                            class="d-flex justify-center"
                            @click="$router.push('/wishlist')"
                        >
                            <div
                                style="width:80px;"
                            >
                                <!-- 아이콘 -->
                                <v-img
                                    class="mx-4"
                                    src="@/assets/home/menu/wishlist_create.png"
                                ></v-img>

                                <!-- 글자 -->
                                <p
                                    class="text-center text-caption mt-1"
                                    style="letter-spacing: -0.7px !important;"
                                >
                                    작성하기
                                </p>
                            </div>
                        </div>

                        <!-- # 둘러보기 -->
                        <div
                            style="width:33%; cursor:pointer;"
                            class="d-flex justify-center"
                            @click="$router.push('/wishlist_list')"
                        >
                            <div
                                style="width:80px;"
                            >
                                <!-- 아이콘 -->
                                <v-img
                                    class="mx-4"
                                    src="@/assets/home/menu/wishlist_read.png"
                                ></v-img>

                                <!-- 글자 -->
                                <p
                                    class="text-center text-caption mt-1"
                                    style="letter-spacing: -0.7px !important;"
                                >
                                    둘러보기
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <v-divider class="mx-2 mt-3 mb-4"></v-divider>

                <!-- # 마지노선 -->
                <div>
                    <!-- # 제목 -->
                    <p
                        class="ml-3 mt-3 font-weight-bold alter_black--text"
                    >
                        마지노선
                    </p>

                    <!-- # 메뉴 -->
                    <div
                        class="d-flex mx-2"
                    >
                        <!-- # 작성하기 -->
                        <div
                            style="width:33%; cursor:pointer;"
                            class="d-flex justify-center"
                            @click="$router.push('/maginotline')"
                        >
                            <div
                                style="width:80px;"
                            >
                                <!-- 아이콘 -->
                                <v-img
                                    class="mx-4"
                                    src="@/assets/home/menu/maginotline_create.png"
                                ></v-img>

                                <!-- 글자 -->
                                <p
                                    class="text-center text-caption mt-1"
                                    style="letter-spacing: -0.7px !important;"
                                >
                                    작성하기
                                </p>
                            </div>
                        </div>

                        <!-- # 둘러보기 -->
                        <div
                            style="width:33%; cursor:pointer;"
                            class="d-flex justify-center"
                            @click="$router.push('/maginotline_list')"
                        >
                            <div
                                style="width:80px;"
                            >
                                <!-- 아이콘 -->
                                <v-img
                                    class="mx-4"
                                    src="@/assets/home/menu/maginotline_read.png"
                                ></v-img>

                                <!-- 글자 -->
                                <p
                                    class="text-center text-caption mt-1"
                                    style="letter-spacing: -0.7px !important;"
                                >
                                    둘러보기
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <v-divider class="mx-2 mt-3 mb-4"></v-divider>

                <!-- # AI -->
                <div>
                    <!-- # 제목 -->
                    <p
                        class="ml-3 mt-3 font-weight-bold alter_black--text"
                    >
                        관상/사주/궁합
                        <v-icon color="orange lighten-1" small class="mb-3px">mdi-creation-outline</v-icon>
                    </p>

                    <!-- # 메뉴 (1단) -->
                    <div
                        class="d-flex mx-2"
                    >
                        <!-- # AI 궁합 -->
                        <div
                            style="width:33%; cursor:pointer;"
                            class="d-flex justify-center"
                            @click="$router.push('/ai/match')"
                        >
                            <div
                                style="width:80px;"
                            >
                                <!-- 아이콘 -->
                                <v-img
                                    class="mx-4"
                                    src="@/assets/home/menu/match.png"
                                ></v-img>

                                <!-- 글자 -->
                                <p
                                    class="text-center text-caption mt-1"
                                    style="letter-spacing: -0.7px !important;"
                                >
                                    AI 궁합
                                </p>
                            </div>
                        </div>

                        <!-- # 오늘의 연애운 -->
                        <div
                            style="width:33%; cursor:pointer;"
                            class="d-flex justify-center"
                            @click="$router.push('/ai/today_fortune')"
                        >
                            <div
                                style="width:80px;"
                            >
                                <!-- 아이콘 -->
                                <v-img
                                    class="mx-4"
                                    src="@/assets/home/menu/today_fortune.png"
                                ></v-img>

                                <!-- 글자 -->
                                <p
                                    class="text-center text-caption mt-1"
                                    style="letter-spacing: -0.7px !important;"
                                >
                                    오늘의 연애운
                                </p>
                            </div>
                        </div>

                        <!-- # 별자리 운세 -->
                        <div
                            style="width:33%; cursor:pointer;"
                            class="d-flex justify-center"
                            @click="$router.push('/ai/star')"
                        >
                            <div
                                style="width:80px;"
                            >
                                <!-- 아이콘 -->
                                <v-img
                                    class="mx-4"
                                    src="@/assets/home/menu/star.png"
                                ></v-img>

                                <!-- 글자 -->
                                <p
                                    class="text-center text-caption mt-1"
                                    style="letter-spacing: -0.7px !important;"
                                >
                                    별자리 운세
                                </p>
                            </div>
                        </div>
                    </div>

                    <!-- # 메뉴 (2단) -->
                    <div
                        class="d-flex mx-2 mt-4"
                    >
                        <!-- # AI 연애상담 -->
                        <div
                            style="width:33%; cursor:pointer;"
                            class="d-flex justify-center"
                            @click="$router.push('/ai/counseling')"
                        >
                            <div
                                style="width:80px;"
                            >
                                <!-- 아이콘 -->
                                <v-img
                                    class="mx-4"
                                    src="@/assets/home/menu/counseling.png"
                                ></v-img>

                                <!-- 글자 -->
                                <p
                                    class="text-center text-caption mt-1"
                                    style="letter-spacing: -0.7px !important;"
                                >
                                    AI 연애상담
                                </p>
                            </div>
                        </div>

                        <!-- # AI 관상 -->
                        <div
                            style="width:33%; cursor:pointer;"
                            class="d-flex justify-center"
                            @click="$router.push('/ai/face')"
                        >
                            <div
                                style="width:80px;"
                            >
                                <!-- 아이콘 -->
                                <v-img
                                    class="mx-4"
                                    src="@/assets/home/menu/face.png"
                                ></v-img>

                                <!-- 글자 -->
                                <p
                                    class="text-center text-caption mt-1"
                                    style="letter-spacing: -0.7px !important;"
                                >
                                    AI 관상
                                </p>
                            </div>
                        </div>

                        <!-- # 관상 궁합 -->
                        <div
                            style="width:33%; cursor:pointer;"
                            class="d-flex justify-center"
                            @click="$router.push('/ai/face_match')"
                        >
                            <div
                                style="width:80px;"
                            >
                                <!-- 아이콘 -->
                                <v-img
                                    class="mx-4"
                                    src="@/assets/home/menu/face_match.png"
                                ></v-img>

                                <!-- 글자 -->
                                <p
                                    class="text-center text-caption mt-1"
                                    style="letter-spacing: -0.7px !important;"
                                >
                                    관상 궁합
                                </p>
                            </div>
                        </div>
                    </div>

                    <!-- # 메뉴 (3단) -->
                    <div
                        class="d-flex mx-2 mt-4"
                    >
                        <!-- # 관상매칭 -->
                        <div
                            style="width:33%; cursor:pointer;"
                            class="d-flex justify-center"
                            @click="$router.push('/ai/face_matching')"
                        >
                            <div
                                style="width:80px;"
                            >
                                <!-- 아이콘 -->
                                <v-img
                                    class="mx-4"
                                    src="@/assets/home/menu/face_matching.png"
                                ></v-img>

                                <!-- 글자 -->
                                <p
                                    class="text-center text-caption mt-1"
                                    style="letter-spacing: -0.7px !important;"
                                >
                                    관상매칭
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </v-sheet>

            <!-- 개인정보 보호를 위해 작성자의 익명성은 100% 보장됩니다 -->
            <p
                class="text-caption mt-2 mb-2 grey--text text-center"
            >
                개인정보 보호를 위해 작성자의 익명성은 100% 보장됩니다
            </p>

            <EventList />

            <!-- 커플 할인, 커플 이벤트 문의 -->
            <p
                class="mx-10 font-weight-medium mb-6 mt-0 text-center" style="line-height:20px;"
            >
                <span class="text-body-2 grey--text text--darken-1 font-weight-medium mb-0">커플 할인, 커플 이벤트 문의</span><br/>
                <span class="primary--text text-caption">keynes30@naver.com</span>
            </p>
        </v-sheet>

        <!-- 로그아웃 상태 -->
        <v-sheet
            v-if="!$store.state.sweetdeal_user.is_logined"
            color="#f4f2f1"
            class="px-4 pt-10 pb-16"
            style="position:relative;"
        >
            <!-- 제목 -->
            <p
                class="text-h6 primary--text font-weight-bold mb-2 mt-6"
                style="line-height:28px;"
            >
                우리는 커플이다
            </p>

            <!-- 카운팅 -->
            <span class="text-subtitle-2 font-weight-bold alter_black--text text--lighten-2">스윗딜 가입 커플수</span>
            <animated-number
                v-if="!loading"
                class="text-subtitle-1 font-weight-bold primary--text ml-6px mr-2px"
                :value="counting" 
                :formatValue="formatToPrice"
                :duration="2000"
            ></animated-number>
            <span class="text-subtitle-2 font-weight-bold alter_black--text text--lighten-2">명</span>

            <!-- 안내문구 -->
            <p
                class="text-body-2 font-weight-medium grey--text text--darken-2 mt-2"
            >
                스윗딜을 간편하게 사용하기 위해서는<br/>
                회원가입이 필요합니다
            </p>

            <!-- 로고 -->
            <v-img
                style="position:absolute; top:50px; right:40px;"
                width="80"
                src="@/assets/logo/default.png"
            ></v-img>

            <!-- 회원가입 하기 -->
            <v-btn
                block
                class="mt-2 rounded-10"
                height="48"
                large
                color="primary"
                style="background:white;"
                outlined
                to="/auth/join"
            >
                회원가입 하기
            </v-btn>
            <!-- 개인정보 보호를 위해 작성자의 익명성은 100% 보장됩니다 -->
            <p
                class="text-caption mt-1 mb-2 grey--text text-center"
            >
                개인정보 보호를 위해 작성자의 익명성은 100% 보장됩니다
            </p>

            <!-- 이미 아이디가 있나요? -->
            <p
                class="text-caption mt-4 mb-2 grey--text text-center"
            >
                이미 아이디가 있나요?
            </p>

            <!-- 로그인하러 가기 -->
            <v-btn
                block
                class="mb-2 rounded-10"
                height="48"
                large
                color="#ffe9ea"
                dark
                depressed
                to="/auth/login"
            >
                <font color="#fea18b">로그인하러 가기</font>
            </v-btn>

            <!-- # 메뉴 -->
            <v-sheet
                class="rounded-10 pa-2 mt-4"
                style="box-shadow:0px 0px 4px 1px #e4e2e1;"
            >
                <!-- # AI -->
                <div>
                    <!-- # 제목 -->
                    <p
                        class="ml-3 mt-3 font-weight-bold alter_black--text"
                    >
                        관상/사주/궁합
                        <v-icon color="orange lighten-1" small class="mb-3px">mdi-creation-outline</v-icon>
                    </p>

                    <!-- # 메뉴 (1단) -->
                    <div
                        class="d-flex mx-2"
                    >
                        <!-- # AI 궁합 -->
                        <div
                            style="width:33%; cursor:pointer;"
                            class="d-flex justify-center"
                            @click="$globalSnackbar.show('로그인 이후 이용가능합니다')"
                        >
                            <div
                                style="width:80px;"
                            >
                                <!-- 아이콘 -->
                                <v-img
                                    class="mx-4"
                                    src="@/assets/home/menu/match.png"
                                ></v-img>

                                <!-- 글자 -->
                                <p
                                    class="text-center text-caption mt-1"
                                    style="letter-spacing: -0.7px !important;"
                                >
                                    AI 궁합
                                </p>
                            </div>
                        </div>

                        <!-- # 오늘의 연애운 -->
                        <div
                            style="width:33%; cursor:pointer;"
                            class="d-flex justify-center"
                            @click="$globalSnackbar.show('로그인 이후 이용가능합니다')"
                        >
                            <div
                                style="width:80px;"
                            >
                                <!-- 아이콘 -->
                                <v-img
                                    class="mx-4"
                                    src="@/assets/home/menu/today_fortune.png"
                                ></v-img>

                                <!-- 글자 -->
                                <p
                                    class="text-center text-caption mt-1"
                                    style="letter-spacing: -0.7px !important;"
                                >
                                    오늘의 연애운
                                </p>
                            </div>
                        </div>

                        <!-- # 별자리 운세 -->
                        <div
                            style="width:33%; cursor:pointer;"
                            class="d-flex justify-center"
                            @click="$globalSnackbar.show('로그인 이후 이용가능합니다')"
                        >
                            <div
                                style="width:80px;"
                            >
                                <!-- 아이콘 -->
                                <v-img
                                    class="mx-4"
                                    src="@/assets/home/menu/star.png"
                                ></v-img>

                                <!-- 글자 -->
                                <p
                                    class="text-center text-caption mt-1"
                                    style="letter-spacing: -0.7px !important;"
                                >
                                    별자리 운세
                                </p>
                            </div>
                        </div>
                    </div>

                    <!-- # 메뉴 (2단) -->
                    <div
                        class="d-flex mx-2 mt-4"
                    >
                        <!-- # AI 연애상담 -->
                        <div
                            style="width:33%; cursor:pointer;"
                            class="d-flex justify-center"
                            @click="$globalSnackbar.show('로그인 이후 이용가능합니다')"
                        >
                            <div
                                style="width:80px;"
                            >
                                <!-- 아이콘 -->
                                <v-img
                                    class="mx-4"
                                    src="@/assets/home/menu/counseling.png"
                                ></v-img>

                                <!-- 글자 -->
                                <p
                                    class="text-center text-caption mt-1"
                                    style="letter-spacing: -0.7px !important;"
                                >
                                    AI 연애상담
                                </p>
                            </div>
                        </div>

                        <!-- # AI 관상 -->
                        <div
                            style="width:33%; cursor:pointer;"
                            class="d-flex justify-center"
                            @click="$router.push('/ai/face')"
                        >
                            <div
                                style="width:80px;"
                            >
                                <!-- 아이콘 -->
                                <v-img
                                    class="mx-4"
                                    src="@/assets/home/menu/face.png"
                                ></v-img>

                                <!-- 글자 -->
                                <p
                                    class="text-center text-caption mt-1"
                                    style="letter-spacing: -0.7px !important;"
                                >
                                    AI 관상
                                </p>
                            </div>
                        </div>

                        <!-- # 관상 궁합 -->
                        <div
                            style="width:33%; cursor:pointer;"
                            class="d-flex justify-center"
                            @click="$router.push('/ai/face_match')"
                        >
                            <div
                                style="width:80px;"
                            >
                                <!-- 아이콘 -->
                                <v-img
                                    class="mx-4"
                                    src="@/assets/home/menu/face_match.png"
                                ></v-img>

                                <!-- 글자 -->
                                <p
                                    class="text-center text-caption mt-1"
                                    style="letter-spacing: -0.7px !important;"
                                >
                                    관상 궁합
                                </p>
                            </div>
                        </div>
                    </div>

                    <!-- # 메뉴 (3단) -->
                    <div
                        class="d-flex mx-2 mt-4"
                    >
                        <!-- # AI 연애상담 -->
                        <div
                            style="width:33%; cursor:pointer;"
                            class="d-flex justify-center"
                            @click="$globalSnackbar.show('로그인 이후 이용가능합니다')"
                        >
                            <div
                                style="width:80px;"
                            >
                                <!-- 아이콘 -->
                                <v-img
                                    class="mx-4"
                                    src="@/assets/home/menu/face_matching.png"
                                ></v-img>

                                <!-- 글자 -->
                                <p
                                    class="text-center text-caption mt-1"
                                    style="letter-spacing: -0.7px !important;"
                                >
                                    AI 연애상담
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </v-sheet>

            <EventList
                class="mt-2"
            />

            <!-- 커플 할인, 커플 이벤트 문의 -->
            <p
                class="mx-10 font-weight-medium mb-6 mt-0 text-center" style="line-height:20px;"
            >
                <span class="text-body-2 grey--text text--darken-1 font-weight-medium mb-0">커플 할인, 커플 이벤트 문의</span><br/>
                <span class="primary--text text-caption">keynes30@naver.com</span>
            </p>
        </v-sheet>
    </div>
</template>
<script>
import AnimatedNumber from "animated-number-vue"
import EventList from "@/components/EventList"

export default {
    components: {
        AnimatedNumber,
        EventList
    },

    data: () => ({
        counting: 0,
        loading: true,

        // 알림함
        alarm_unwatched: 0,
        ready: false
    }),

    mounted(){
        // 계약 전체 불러오기
        this.loadContract()

        this.caculate_counting()
    },

    methods: {
        // 계약 전체 불러오기
        loadContract(){
            this.$http.post("/api/contract/select")
            .then((res) => {
                if(res.data.length){
                    let temp_list = res.data.filter(e => e.receiver == this.$store.state.sweetdeal_user.name && e.status == '서명대기중')

                    this.alarm_unwatched = temp_list.length
                }
            })
        },

        caculate_counting(){
            this.loading = true
            const startDate = new Date('2024-06-24')
            const currentDate = new Date()
            const diffTime = Math.abs(currentDate - startDate)
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
            // const randomFactor = Math.floor(Math.random() * 3) + 1 // 1~3 랜덤 값
            this.counting = 450 + (diffDays * 5.6) // 하루에 4.5명 씩 증가
            console.log(this.counting)
            this.loading = false
        },

        formatToPrice(value) {
            var num=Number(value).toFixed(0)
            return `${Number(num).toLocaleString()}`
        },
    },
}
</script>
<style scoped>
.v-btn::before {
    background-color: transparent;
}

.custom-list:hover {
    color: #43E5FF !important;
}

.custom-list:active {
    color: #43E5FF !important;
}

.custom-btn{
    background-color: transparent;
}
</style>