<template>
    <v-sheet min-height="500" class="pa-14">
        <v-btn
            @click="callOpenAI()"
        >
            테스트
        </v-btn>

        <v-divider class="my-10"></v-divider>

        {{response}}

        <v-divider class="my-8"></v-divider>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        apiKey: 'sk-proj-XAXWwzMSlSzeKCYZvNPJT3BlbkFJxhdW1rGp6Do6Sej0JvQS',
        prompt: '김동재 1993년 8월 8일 김민지 2004년 5월 7일 의 궁합을 100자 이내로 답변해줘',
        response: ''
    }),

    mounted(){

    },

    methods: {
        async callOpenAI() {
            try {
                const result = await this.$http.post('https://api.openai.com/v1/chat/completions', {
                    model: 'gpt-3.5-turbo',
                    messages: [{ role: 'user', content: this.prompt }],
                    temperature: 0,
                    n: 1
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.apiKey}`
                    }
                })
                console.log(result)
                this.response = result.data.choices[0].message.content
            } catch (error) {
                console.error('Error calling OpenAI API:', error)
            }
        }
    }
}
</script> 