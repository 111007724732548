<template>
    <div>
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">키워드 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="ma-2 pa-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-4">
                <v-sheet class="d-flex" width="660">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="primary"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-radio-group
                v-model="selected"
                hide-details
            >
                <v-data-table
                    hide-default-header
                    :items="list"
                    :footer-props="{
                        'items-per-page-options': [8, 20, 100, -1],
                        'itemsPerPageText': '페이지당 키워드 수'
                    }"
                    no-data-text=""
                >
                    <template v-slot:header>
                        <tr>
                            <th style="width:52px;"></th>
                            <th style="width:140px">대분류</th>
                            <th style="width:180px">소분류</th>
                            <th style="width:100%;">키워드</th>
                            <th style="width:200px">작성일자</th>
                        </tr>
                    </template>
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                <v-radio
                                    class="ml-1"
                                    hide-details
                                    dense
                                    :value="item"
                                    @change="selectedItem = item"
                                ></v-radio>
                            </td>
                            <td><font class="d-block text-truncate" style="width:120px;">{{item.category_main}}</font></td>
                            <td><font class="d-block text-truncate" style="width:160px;">{{item.category_sub}}</font></td>
                            <td><font class="d-block text-truncate" style="width:500px;">{{item.text}}</font></td>
                            <td><font class="d-block text-truncate" style="width:180px;">{{new Date(item.created).toLocaleDateString()}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    class="px-6 ml-3"
                    color="orange"
                    large
                    dark
                    depressed
                    @click="updateOpen(item)"
                >
                    <v-icon small left>mdi-database-edit-outline</v-icon>
                    수정하기
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="blue"
                    large
                    dark
                    depressed
                    @click="dialog.insert = true"
                >
                    <v-icon small left>mdi-database-plus-outline</v-icon>
                    추가하기
                </v-btn>
                <v-btn
                    class="px-6 ml-3"
                    color="red"
                    large
                    dark
                    depressed
                    @click="deleteItem()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    삭제하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- 키워드 수정하기 -->
        <v-dialog v-model="dialog.update" width="440" content-class="rounded-10">
            <v-sheet class="pa-4">
                <p
                    class="text-h6 font-weight-bold grey--text text--darken-3 text-center"
                >
                    키워드 수정하기
                </p>

                <v-sheet outlined class="pa-1 rounded">
                    <v-select
                        dense
                        solo
                        flat
                        hide-details
                        placeholder="메인 카테고리"
                        :items="select_list.category_main"
                        v-model="update.category_main"
                    ></v-select>
                </v-sheet>
                <v-sheet outlined class="pa-1 mt-2 rounded">
                    <v-select
                        dense
                        solo
                        flat
                        hide-details
                        placeholder="서브 카테고리"
                        :items="select_list.category_sub"
                        v-model="update.category_sub"
                    ></v-select>
                </v-sheet>
                <v-sheet outlined class="pa-1 mt-2 rounded">
                    <v-text-field
                        v-model="update.text"
                        dense
                        solo
                        flat
                        hide-details
                        placeholder="키워드"
                    ></v-text-field>
                </v-sheet>
                <v-sheet class="mt-2 pa-2 d-flex justify-center">
                    <v-btn
                        class="rounded-10 px-6 mr-4"
                        color="grey"
                        large
                        dark
                        depressed
                        @click="dialog.update = false"
                    >
                        <v-icon small left>mdi-cancel</v-icon>
                        취소하기
                    </v-btn>
                    <v-btn
                        class="rounded-10 px-6"
                        color="blue"
                        large
                        dark
                        depressed
                        @click="updateSubmit()"
                    >
                        <v-icon small left>mdi-database-edit-outline</v-icon>
                        수정하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>

        <!-- 키워드 추가하기 -->
        <v-dialog
            v-model="dialog.insert"
            width="420"
            content-class="rounded-10"
        >
            <v-sheet class="py-4 px-10">
                <p
                    class="text-h6 font-weight-bold grey--text text--darken-3 text-center"
                >
                    키워드 추가하기
                </p>

                <v-sheet outlined class="pa-1 rounded">
                    <v-select
                        dense
                        solo
                        flat
                        hide-details
                        placeholder="메인 카테고리"
                        :items="select_list.category_main"
                        v-model="insert.category_main"
                    ></v-select>
                </v-sheet>
                <v-sheet outlined class="pa-1 mt-2 rounded">
                    <v-select
                        dense
                        solo
                        flat
                        hide-details
                        placeholder="서브 카테고리"
                        :items="select_list.category_sub"
                        v-model="insert.category_sub"
                    ></v-select>
                </v-sheet>
                <v-sheet outlined class="pa-1 mt-2 rounded">
                    <v-text-field
                        v-model="insert.text"
                        dense
                        solo
                        flat
                        hide-details
                        placeholder="키워드"
                    ></v-text-field>
                </v-sheet>
                <v-sheet class="mt-4 pa-2 d-flex justify-center">
                    <v-btn
                        class="rounded-10 px-6 mr-4"
                        color="grey"
                        large
                        dark
                        depressed
                        @click="dialog.insert = false"
                    >
                        <v-icon small left>mdi-cancel</v-icon>
                        취소하기
                    </v-btn>
                    <v-btn
                        class="rounded-10 px-6"
                        color="blue"
                        large
                        dark
                        depressed
                        @click="insertSubmit()"
                    >
                        <v-icon small left>mdi-database-edit-outline</v-icon>
                        추가하기
                    </v-btn>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
export default {
    data: () => ({
        // 선택목록
        select_list: {
            category_main: [
                "계약",
                "위시리스트",
                "마지노선",
            ],

            category_sub: [
                "의무사항",
                "위반시 조치",
                "약속항목",
                "패널티항목",
            ],

            search_type: [
                "메인 카테고리",
                "서브 카테고리",
                "키워드"
            ]
        },

        // 검색
        search_type: "키워드",
        keyword: "",

        // 목록
        list: [],
        selected: false,
        selectedItem: {},

        // 수정하기
        update: {
            category_main: "",
            category_sub: "",
            text: "",
        },

        // 추가하기
        insert: {
            category_main: "",
            category_sub: "",
            text: "",
        },

        // Dialog
        dialog: {
            update: false,
            insert: false
        }
    }),

    mounted(){
        // 검색
        this.search()
    },

    methods: {
        // 검색
        search(){
            this.$http.post('/api/keyword/select/search', {
                params: {
                    search_type: this.search_type,
                    keyword: this.keyword
                }
            }).then((res) => {
                this.list = res.data
            })
        },

        // 수정 열기
        updateOpen(){
            if(!Object.keys(this.selectedItem).length){
                this.$globalSnackbar.show("선택한 쿠폰이 없습니다")
            }else{
                this.update = Object.assign({}, this.selectedItem)
                this.dialog.update = true

            }
        },

        // 수정하기 제출하기
        updateSubmit(){
            this.$http.post('/api/keyword/update', {
                params: {
                    category_main: this.update.category_main,
                    category_sub: this.update.category_sub,
                    text: this.update.text,
                    id: this.selectedItem.id
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    this.search()
                    this.dialog.update = false
                    this.selected = false
                    this.selectedItem = {}
                }
            })
        },

        // 추가하기
        insertSubmit(){
            this.$http.post('/api/keyword/insert', {
                params: {
                    category_main: this.insert.category_main,
                    category_sub: this.insert.category_sub,
                    text: this.insert.text,
                }
            }).then(res => {
                if(res.data.affectedRows){
                    this.dialog.insert = false
                    this.search()
                }
            })
        },
        
        // 삭제하기
        deleteItem(){
            if(!Object.keys(this.selectedItem).length){
                this.$globalSnackbar.show("선택한 키워드가 없습니다")
            }else{
                if(confirm("정말 해당 키워드를 삭제하겠습니까?")){
                    this.$http.post('/api/keyword/delete', {
                        params: {
                            id: this.selectedItem.id
                        }
                    }).then((res) => {
                        if(res.data.affectedRows){
                            this.search()
                        }
                    })
                }
            }
        }
    }
}
</script>