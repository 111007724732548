<template>
    <div>
        <!-- 상단 -->
        <div>
            <!-- 제목 -->
            <p
                class="text-h6 font-weight-bold mb-16"
                style="line-height:28px;"
            >
                <span class="primary--text">계약사항</span><br/>
            </p>

            <!-- 로고 -->
            <v-img
                style="position:absolute; top:80px; right:40px;"
                width="80"
                src="@/assets/logo/default.png"
            ></v-img>
        </div>

        <!-- 본문 -->
        <div>
            <!-- 의무사항이 없을때 -->
            <v-sheet
                v-for="(item, index) in list" :key="index"
                class="mb-8 rounded-10 pt-2 pb-4 px-4"
            >
                <p class="mt-4 mb-4 text-subtitle-1 font-weight-bold">
                    의무사항
                </p>

                <p class="mx-2">
                    1. {{ item.p1 + " " + item.p2 + " " + item.p3 + " " + item.p4 + " " + item.p_memo }}
                </p>

                <p class="mt-4 mb-4 text-subtitle-1 font-weight-bold">
                    위 내용을 지키지 않으면,
                </p>

                <p class="mx-2 mb-2">
                    <span v-if="item.v_memo != ''">1. </span>
                    {{ item.v1 + " " + item.v2 + " " + item.v3 + " " + item.v4 + " " + item.v_memo }}
                </p>

                <p class="mx-2">
                    <span v-if="item.v_memo_2 != ''">2. </span>
                    {{ item.v1_2 + " " + item.v2_2 + " " + item.v3_2 + " " + item.v4_2 + " " + item.v_memo_2 }}
                </p>


                <div
                    class="d-flex justify-center mt-4"
                >
                    <v-btn
                        color="primary lighten-4 primary--text"
                        class="rounded-10"
                        depressed
                        @click="updateOpen(item)"
                    >
                        <v-icon size="16" class="pt-2px mr-1">mdi-pencil</v-icon>
                        수정하기
                    </v-btn>
                </div>
            </v-sheet>

            <!-- 의무사항이 없을때 -->
            <p
                v-if="!list.length"
                class="text-center text-h6 py-16 font-weight-medium grey--text"
            >
                의무사항을 추가해주세요
            </p>

            <v-btn
                block
                class="rounded-10 mb-4"
                height="48"
                large
                color="grey lighten-2"
                dark
                depressed
                @click="bottom_sheet.add = true"
            >
                <span class="primary--text">의무사항 추가하기</span>
            </v-btn>

            <!-- 다음 -->
            <v-btn
                block
                class="rounded-10"
                height="48"
                large
                color="primary"
                dark
                depressed
                @click="submit()"
            >
                다음
            </v-btn>
        </div>

        <!-- 추가  -->
        <v-bottom-sheet
            inset
            v-model="bottom_sheet.add"
            scrollable
        >
            <v-sheet
                class="elevation-0 pt-5 pb-14 px-4"
                style="border-top-left-radius:30px; border-top-right-radius:30px; position:relative; overflow:scroll;"
            >
                <v-icon
                    style="position:absolute; top:20px; right:20px;"
                    size="32"
                    color="grey"
                    @click="bottom_sheet.add = false"
                >
                    mdi-close
                </v-icon>
                <p class="ml-1 mb-4 text-h6 font-weight-bold">
                    의무사항
                </p>
                <div
                    class="ml-1"
                >
                    <v-btn
                        @click="p1 = $store.state.contract.my.love_name"
                        depressed
                        :class="p1 == $store.state.contract.my.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        {{$store.state.contract.my.love_name}}
                    </v-btn>
                    <v-btn
                        @click="p1 = $store.state.contract.partner.love_name"
                        depressed
                        :class="p1 == $store.state.contract.partner.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        {{$store.state.contract.partner.love_name}}
                    </v-btn>
                </div>
                <div
                    class="ml-1 mt-2"
                >
                    <v-btn
                        @click="p2 = '은/는'"
                        depressed
                        :class="p2 == '은/는'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        은/는
                    </v-btn>
                    <v-btn
                        @click="p2 = '와/과'"
                        depressed
                        :class="p2 == '와/과'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        와/과
                    </v-btn>
                </div>

                <div
                    class="ml-1 mt-2"
                >
                    <v-btn
                        @click="p3 = $store.state.contract.my.love_name"
                        depressed
                        :class="p3 == $store.state.contract.my.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        {{$store.state.contract.my.love_name}}
                    </v-btn>
                    <v-btn
                        @click="p3 = $store.state.contract.partner.love_name"
                        depressed
                        :class="p3 == $store.state.contract.partner.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        {{$store.state.contract.partner.love_name}}
                    </v-btn>
                    <v-btn
                        @click="p3 = ''"
                        depressed
                        :class="p3 == ''? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        없음
                    </v-btn>
                </div>

                <div
                    class="ml-1 mt-2 mb-4"
                >
                    <v-btn
                        @click="p4 = '은/는'"
                        depressed
                        :class="p4 == '은/는'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        은/는
                    </v-btn>
                    <v-btn
                        @click="p4 = '에게'"
                        depressed
                        :class="p4 == '에게'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        에게
                    </v-btn>
                    <v-btn
                        @click="p4 = '을/를'"
                        depressed
                        :class="p4 == '을/를'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        을/를
                    </v-btn>
                    <v-btn
                        @click="p4 = ''"
                        depressed
                        :class="p4 == ''? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        없음
                    </v-btn>
                </div>

                <p class="ml-1 mt-4 mb-2 text-subtitle-1 font-weight-bold">
                    내용
                </p>
                <div
                    class="d-flex justify-center mb-1"
                >
                    <v-text-field
                        class="rounded-lg mx-1"
                        outlined
                        hide-details
                        placeholder="내용을 입력해주세요."
                        v-model="p_memo"
                    ></v-text-field>
                </div>

                <!-- 의무사항 선택목록 -->
                <div>
                    <v-btn
                        v-for="item in duty_list" :key="item"
                        @click="p_memo = item"
                        depressed
                        class="
                            px-3
                            ma-1
                            rounded-lg
                            grey lighten-2
                            grey--text
                            font-weight-light
                            text-body-2
                        "
                    >
                        {{item}}
                    </v-btn>
                </div>

                <p
                    v-if="violation == false"
                    class="ml-1 mt-6 mb-2 grey--text text-subtitle-1 font-weight-bold"
                    style="cursor:pointer;"
                    @click="violation = true"
                >
                    <v-icon color="grey lighten-1" class="pb-3px">mdi-plus-circle</v-icon>
                    위 내용을 지키지 않으면
                </p>

                <p
                    v-if="violation == true"
                    class="ml-1 mt-6 mb-2 text-subtitle-1 font-weight-bold"
                    style="cursor:pointer;"
                    @click="violation = false"
                >
                    <v-icon color="primary" class="pb-3px">mdi-check-circle</v-icon>
                    위 내용을 지키지 않으면
                </p>

                <div
                    v-if="violation == true"
                    class="ml-1"
                >
                    <v-btn
                        @click="v1 = $store.state.contract.my.love_name"
                        depressed
                        :class="v1 == $store.state.contract.my.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        {{$store.state.contract.my.love_name}}
                    </v-btn>
                    <v-btn
                        @click="v1 = $store.state.contract.partner.love_name"
                        depressed
                        :class="v1 == $store.state.contract.partner.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        {{$store.state.contract.partner.love_name}}
                    </v-btn>
                </div>
                <div
                    v-if="violation == true"
                    class="ml-1 mt-2"
                >
                    <v-btn
                        @click="v2 = '은/는'"
                        depressed
                        :class="v2 == '은/는'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        은/는
                    </v-btn>
                    <v-btn
                        @click="v2 = '와/과'"
                        depressed
                        :class="v2 == '와/과'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        와/과
                    </v-btn>
                </div>

                <div
                    v-if="violation == true"
                    class="ml-1 mt-2"
                >
                    <v-btn
                        @click="v3 = $store.state.contract.my.love_name"
                        depressed
                        :class="v3 == $store.state.contract.my.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        {{$store.state.contract.my.love_name}}
                    </v-btn>
                    <v-btn
                        @click="v3 = $store.state.contract.partner.love_name"
                        depressed
                        :class="v3 == $store.state.contract.partner.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        {{$store.state.contract.partner.love_name}}
                    </v-btn>
                    <v-btn
                        @click="v3 = ''"
                        depressed
                        :class="v3 == ''? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        없음
                    </v-btn>
                </div>

                <div
                    v-if="violation == true"
                    class="ml-1 mt-2 mb-4"
                >
                    <v-btn
                        @click="v4 = '은/는'"
                        depressed
                        :class="v4 == '은/는'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        은/는
                    </v-btn>
                    <v-btn
                        @click="v4 = '에게'"
                        depressed
                        :class="v4 == '에게'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        에게
                    </v-btn>
                    <v-btn
                        @click="v4 = '을/를'"
                        depressed
                        :class="v4 == '을/를'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        을/를
                    </v-btn>
                    <v-btn
                        @click="v4 = ''"
                        depressed
                        :class="v4 == ''? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        없음
                    </v-btn>
                </div>

                <div
                    v-if="violation == true"
                    class="d-flex justify-center mb-1"
                >
                    <v-text-field
                        class="rounded-lg mx-1"
                        outlined
                        hide-details
                        placeholder="내용을 입력해주세요."
                        v-model="v_memo"
                    ></v-text-field>
                </div>

                <!-- 위반시 조치 선택목록 -->
                <div
                    v-if="violation == true"
                >
                    <v-btn
                        v-for="item in penalty_list" :key="item"
                        @click="v_memo = item"
                        depressed
                        class="
                            px-3
                            ma-1
                            rounded-lg
                            grey lighten-2
                            grey--text
                            font-weight-light
                            text-body-2
                        "
                    >
                        {{item}}
                    </v-btn>
                </div>

                <div
                    v-if="violation"
                >
                    <p
                        v-if="violation2 == false"
                        class="ml-1 mt-6 mb-2 grey--text text-subtitle-1 font-weight-bold"
                        style="cursor:pointer;"
                        @click="violation2 = true"
                    >
                        <v-icon color="grey lighten-1" class="pb-3px">mdi-plus-circle</v-icon>
                        위 내용을 지키지 않으면 (2)
                    </p>

                    <p
                        v-if="violation2 == true"
                        class="ml-1 mt-6 mb-2 text-subtitle-1 font-weight-bold"
                        style="cursor:pointer;"
                        @click="
                            violation2 = false;
                            v1_2 = '';
                            v2_2= '';
                            v3_2= '';
                            v4_2= '';
                            v_memo_2= '';
                        "
                    >
                        <v-icon color="primary" class="pb-3px">mdi-check-circle</v-icon>
                        위 내용을 지키지 않으면 (2)
                    </p>
                </div>

                <div
                    v-if="violation2 == true"
                    class="ml-1"
                >
                    <v-btn
                        @click="v1_2 = $store.state.contract.my.love_name"
                        depressed
                        :class="v1_2 == $store.state.contract.my.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        {{$store.state.contract.my.love_name}}
                    </v-btn>
                    <v-btn
                        @click="v1_2 = $store.state.contract.partner.love_name"
                        depressed
                        :class="v1_2 == $store.state.contract.partner.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        {{$store.state.contract.partner.love_name}}
                    </v-btn>
                </div>
                <div
                    v-if="violation2 == true"
                    class="ml-1 mt-2"
                >
                    <v-btn
                        @click="v2_2 = '은/는'"
                        depressed
                        :class="v2_2 == '은/는'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        은/는
                    </v-btn>
                    <v-btn
                        @click="v2_2 = '와/과'"
                        depressed
                        :class="v2_2 == '와/과'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        와/과
                    </v-btn>
                </div>

                <div
                    v-if="violation2 == true"
                    class="ml-1 mt-2"
                >
                    <v-btn
                        @click="v3_2 = $store.state.contract.my.love_name"
                        depressed
                        :class="v3_2 == $store.state.contract.my.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        {{$store.state.contract.my.love_name}}
                    </v-btn>
                    <v-btn
                        @click="v3_2 = $store.state.contract.partner.love_name"
                        depressed
                        :class="v3_2 == $store.state.contract.partner.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        {{$store.state.contract.partner.love_name}}
                    </v-btn>
                    <v-btn
                        @click="v3_2 = ''"
                        depressed
                        :class="v3_2 == ''? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        없음
                    </v-btn>
                </div>

                <div
                    v-if="violation2 == true"
                    class="ml-1 mt-2 mb-4"
                >
                    <v-btn
                        @click="v4_2 = '은/는'"
                        depressed
                        :class="v4_2 == '은/는'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        은/는
                    </v-btn>
                    <v-btn
                        @click="v4_2 = '에게'"
                        depressed
                        :class="v4_2 == '에게'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        에게
                    </v-btn>
                    <v-btn
                        @click="v4_2 = '을/를'"
                        depressed
                        :class="v4_2 == '을/를'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        을/를
                    </v-btn>
                    <v-btn
                        @click="v4_2 = ''"
                        depressed
                        :class="v4_2 == ''? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        없음
                    </v-btn>
                </div>

                <div
                    v-if="violation2 == true"
                    class="d-flex justify-center mb-1"
                >
                    <v-text-field
                        class="rounded-lg mx-1"
                        outlined
                        hide-details
                        placeholder="내용을 입력해주세요."
                        v-model="v_memo_2"
                    ></v-text-field>
                </div>

                <!-- 위반시 조치 선택목록 -->
                <div
                    v-if="violation2 == true"
                >
                    <v-btn
                        v-for="item in penalty_list" :key="item"
                        @click="v_memo_2 = item"
                        depressed
                        class="
                            px-3
                            ma-1
                            rounded-lg
                            grey lighten-2
                            grey--text
                            font-weight-light
                            text-body-2
                        "
                    >
                        {{item}}
                    </v-btn>
                </div>

                <br />
                <v-btn
                    height="48"
                    class="rounded-10 mt-2"
                    large
                    depressed
                    dark
                    color="primary"
                    block
                    @click="bottom_sheet.add = false; addItem()"
                >
                    완료하기
                </v-btn>
            </v-sheet>
        </v-bottom-sheet>

        <!-- 수정  -->
        <v-bottom-sheet
            inset
            v-model="bottom_sheet.update"
            scrollable
        >
            <v-sheet
                class="elevation-0 pt-10 pb-14 px-4"
                style="border-top-left-radius:30px; border-top-right-radius:30px; position:relative; overflow:scroll;"
            >
                <v-icon
                    style="position:absolute; top:16px; left:12px;"
                    size="32"
                    color="grey"
                    @click="bottom_sheet.update = false"
                >
                    mdi-chevron-left
                </v-icon>
                <p class="ml-1 mt-4 mb-4 text-h6 font-weight-bold">
                    의무사항
                </p>
                <div
                    class="ml-1"
                >
                    <v-btn
                        @click="update.p1 = $store.state.contract.my.love_name"
                        depressed
                        :class="update.p1 == $store.state.contract.my.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        {{$store.state.contract.my.love_name}}
                    </v-btn>
                    <v-btn
                        @click="update.p1 = $store.state.contract.partner.love_name"
                        depressed
                        :class="update.p1 == $store.state.contract.partner.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        {{$store.state.contract.partner.love_name}}
                    </v-btn>
                </div>
                <div
                    class="ml-1 mt-2"
                >
                    <v-btn
                        @click="update.p2 = '은/는'"
                        depressed
                        :class="update.p2 == '은/는'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        은/는
                    </v-btn>
                    <v-btn
                        @click="update.p2 = '와/과'"
                        depressed
                        :class="update.p2 == '와/과'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        와/과
                    </v-btn>
                </div>

                <div
                    class="ml-1 mt-2"
                >
                    <v-btn
                        @click="update.p3 = $store.state.contract.my.love_name"
                        depressed
                        :class="update.p3 == $store.state.contract.my.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        {{$store.state.contract.my.love_name}}
                    </v-btn>
                    <v-btn
                        @click="update.p3 = $store.state.contract.partner.love_name"
                        depressed
                        :class="update.p3 == $store.state.contract.partner.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        {{$store.state.contract.partner.love_name}}
                    </v-btn>
                    <v-btn
                        @click="update.p3 = ''"
                        depressed
                        :class="update.p3 == ''? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        없음
                    </v-btn>
                </div>

                <div
                    class="ml-1 mt-2 mb-4"
                >
                    <v-btn
                        @click="update.p4 = '은/는'"
                        depressed
                        :class="update.p4 == '은/는'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        은/는
                    </v-btn>
                    <v-btn
                        @click="update.p4 = '에게'"
                        depressed
                        :class="update.p4 == '에게'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        에게
                    </v-btn>
                    <v-btn
                        @click="update.p4 = '을/를'"
                        depressed
                        :class="update.p4 == '을/를'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        을/를
                    </v-btn>
                    <v-btn
                        @click="update.p4 = ''"
                        depressed
                        :class="update.p4 == ''? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        없음
                    </v-btn>
                </div>

                <p class="ml-1 mt-4 mb-2 text-subtitle-1 font-weight-bold">
                    내용
                </p>
                <div
                    class="d-flex justify-center mb-1"
                >
                    <v-text-field
                        class="rounded-lg mx-1"
                        outlined
                        hide-details
                        placeholder="내용을 입력해주세요."
                        v-model="update.p_memo"
                    ></v-text-field>
                </div>
                
                <!-- 의무사항 선택목록 -->
                <div>
                    <v-btn
                        v-for="item in duty_list" :key="item"
                        @click="update.p_memo = item"
                        depressed
                        class="
                            px-3
                            ma-1
                            rounded-lg
                            grey lighten-2
                            grey--text
                            font-weight-light
                            text-body-2
                        "
                    >
                        {{item}}
                    </v-btn>
                </div>
                
                <p
                    v-if="violation == false"
                    class="ml-1 mt-6 mb-2 grey--text text-subtitle-1 font-weight-bold"
                    style="cursor:pointer;"
                    @click="violation = true;"
                >
                    <v-icon color="grey lighten-1" class="pb-3px">mdi-plus-circle</v-icon>
                    위 내용을 지키지 않으면
                </p>

                <p
                    v-if="violation == true"
                    class="ml-1 mt-6 mb-2 text-subtitle-1 font-weight-bold"
                    style="cursor:pointer;"
                    @click="
                        violation = false;
                        update.v1 = '';
                        update.v2= '';
                        update.v3= '';
                        update.v4= '';
                        update.v_memo= '';
                    "
                >
                    <v-icon color="primary" class="pb-3px">mdi-check-circle</v-icon>
                    위 내용을 지키지 않으면
                </p>

                <div
                    v-if="violation == true"
                    class="ml-1"
                >
                    <v-btn
                        @click="update.v1 = $store.state.contract.my.love_name"
                        depressed
                        :class="update.v1 == $store.state.contract.my.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        {{$store.state.contract.my.love_name}}
                    </v-btn>
                    <v-btn
                        @click="update.v1 = $store.state.contract.partner.love_name"
                        depressed
                        :class="update.v1 == $store.state.contract.partner.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        {{$store.state.contract.partner.love_name}}
                    </v-btn>
                </div>
                <div
                    v-if="violation == true"
                    class="ml-1 mt-2"
                >
                    <v-btn
                        @click="update.v2 = '은/는'"
                        depressed
                        :class="update.v2 == '은/는'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        은/는
                    </v-btn>
                    <v-btn
                        @click="update.v2 = '와/과'"
                        depressed
                        :class="update.v2 == '와/과'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        와/과
                    </v-btn>
                </div>

                <div
                    v-if="violation == true"
                    class="ml-1 mt-2"
                >
                    <v-btn
                        @click="update.v3 = $store.state.contract.my.love_name"
                        depressed
                        :class="update.v3 == $store.state.contract.my.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        {{$store.state.contract.my.love_name}}
                    </v-btn>
                    <v-btn
                        @click="update.v3 = $store.state.contract.partner.love_name"
                        depressed
                        :class="update.v3 == $store.state.contract.partner.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        {{$store.state.contract.partner.love_name}}
                    </v-btn>
                    <v-btn
                        @click="update.v3 = '없음'"
                        depressed
                        :class="update.v3 == '없음'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        없음
                    </v-btn>
                </div>

                <div
                    v-if="violation == true"
                    class="ml-1 mt-2 mb-4"
                >
                    <v-btn
                        @click="update.v4 = '은/는'"
                        depressed
                        :class="update.v4 == '은/는'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        은/는
                    </v-btn>
                    <v-btn
                        @click="update.v4 = '에게'"
                        depressed
                        :class="update.v4 == '에게'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        에게
                    </v-btn>
                    <v-btn
                        @click="update.v4 = '을/를'"
                        depressed
                        :class="update.v4 == '을/를'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        을/를
                    </v-btn>
                    <v-btn
                        @click="update.v4 = '없음'"
                        depressed
                        :class="update.v4 == '없음'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        없음
                    </v-btn>
                </div>

                <div
                    v-if="violation == true"
                    class="d-flex justify-center mb-1"
                >
                    <v-text-field
                        class="rounded-lg mx-1"
                        outlined
                        hide-details
                        placeholder="내용을 입력해주세요."
                        v-model="update.v_memo"
                    ></v-text-field>
                </div>
                
                <!-- 위반시 조치 선택목록 -->
                <div
                    v-if="violation == true"
                >
                    <v-btn
                        v-for="item in penalty_list" :key="item"
                        @click="update.v_memo = item"
                        depressed
                        class="
                            px-3
                            ma-1
                            rounded-lg
                            grey lighten-2
                            grey--text
                            font-weight-light
                            text-body-2
                        "
                    >
                        {{item}}
                    </v-btn>
                </div>

                <p
                    v-if="violation2 == false"
                    class="ml-1 mt-6 mb-2 grey--text text-subtitle-1 font-weight-bold"
                    style="cursor:pointer;"
                    @click="violation2 = true;"
                >
                    <v-icon color="grey lighten-1" class="pb-3px">mdi-plus-circle</v-icon>
                    위 내용을 지키지 않으면 (2)
                </p>

                <p
                    v-if="violation2 == true"
                    class="ml-1 mt-6 mb-2 text-subtitle-1 font-weight-bold"
                    style="cursor:pointer;"
                    @click="
                        violation2 = false;
                        update.v1_2 = '';
                        update.v2_2= '';
                        update.v3_2= '';
                        update.v4_2= '';
                        update.v_memo_2= '';
                    "
                >
                    <v-icon color="primary" class="pb-3px">mdi-check-circle</v-icon>
                    위 내용을 지키지 않으면 (2)
                </p>

                <div
                    v-if="violation2 == true"
                    class="ml-1"
                >
                    <v-btn
                        @click="update.v1_2 = $store.state.contract.my.love_name"
                        depressed
                        :class="update.v1_2 == $store.state.contract.my.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        {{$store.state.contract.my.love_name}}
                    </v-btn>
                    <v-btn
                        @click="update.v1_2 = $store.state.contract.partner.love_name"
                        depressed
                        :class="update.v1_2 == $store.state.contract.partner.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        {{$store.state.contract.partner.love_name}}
                    </v-btn>
                </div>
                <div
                    v-if="violation2 == true"
                    class="ml-1 mt-2"
                >
                    <v-btn
                        @click="update.v2_2 = '은/는'"
                        depressed
                        :class="update.v2_2 == '은/는'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        은/는
                    </v-btn>
                    <v-btn
                        @click="update.v2_2 = '와/과'"
                        depressed
                        :class="update.v2_2 == '와/과'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        와/과
                    </v-btn>
                </div>

                <div
                    v-if="violation2 == true"
                    class="ml-1 mt-2"
                >
                    <v-btn
                        @click="update.v3_2 = $store.state.contract.my.love_name"
                        depressed
                        :class="update.v3_2 == $store.state.contract.my.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        {{$store.state.contract.my.love_name}}
                    </v-btn>
                    <v-btn
                        @click="update.v3_2 = $store.state.contract.partner.love_name"
                        depressed
                        :class="update.v3_2 == $store.state.contract.partner.love_name? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        {{$store.state.contract.partner.love_name}}
                    </v-btn>
                    <v-btn
                        @click="update.v3_2 = '없음'"
                        depressed
                        :class="update.v3_2 == '없음'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        없음
                    </v-btn>
                </div>

                <div
                    v-if="violation2 == true"
                    class="ml-1 mt-2 mb-4"
                >
                    <v-btn
                        @click="update.v4_2 = '은/는'"
                        depressed
                        :class="update.v4_2 == '은/는'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                    >
                        은/는
                    </v-btn>
                    <v-btn
                        @click="update.v4_2 = '에게'"
                        depressed
                        :class="update.v4_2 == '에게'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        에게
                    </v-btn>
                    <v-btn
                        @click="update.v4_2 = '을/를'"
                        depressed
                        :class="update.v4_2 == '을/를'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        을/를
                    </v-btn>
                    <v-btn
                        @click="update.v4_2 = '없음'"
                        depressed
                        :class="update.v4_2 == '없음'? 'white--text black rounded-lg font-weight-light text-body-1':'grey lighten-2 grey--text rounded-lg font-weight-light text-body-1'"
                        class="ml-2"
                    >
                        없음
                    </v-btn>
                </div>

                <div
                    v-if="violation2 == true"
                    class="d-flex justify-center mb-1"
                >
                    <v-text-field
                        class="rounded-lg mx-1"
                        outlined
                        hide-details
                        placeholder="내용을 입력해주세요."
                        v-model="update.v_memo_2"
                    ></v-text-field>
                </div>
                
                <!-- 위반시 조치 선택목록 -->
                <div
                    v-if="violation2 == true"
                >
                    <v-btn
                        v-for="item in penalty_list" :key="item"
                        @click="update.v_memo_2 = item"
                        depressed
                        class="
                            px-3
                            ma-1
                            rounded-lg
                            grey lighten-2
                            grey--text
                            font-weight-light
                            text-body-2
                        "
                    >
                        {{item}}
                    </v-btn>
                </div>

                <v-btn
                    height="48"
                    class="rounded-10 mt-2"
                    large
                    depressed
                    dark
                    color="primary"
                    block
                    @click="updateSubmit()"
                >
                    완료하기
                </v-btn>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
    data: () => ({
        p1: "",
        p2: "",
        p3: "",
        p4: "",
        p_memo: "",

        v1: "",
        v2: "",
        v3: "",
        v4: "",
        v_memo: "",

        v1_2: "",
        v2_2: "",
        v3_2: "",
        v4_2: "",
        v_memo_2: "",

        violation: false,
        violation2: false,

        list: [],


        update: {
            p1: "",
            p2: "",
            p3: "",
            p4: "",
            p_memo: "",

            v1: "",
            v2: "",
            v3: "",
            v4: "",
            v_memo: "",

            v1_2: "",
            v2_2: "",
            v3_2: "",
            v4_2: "",
            v_memo_2: "",
        },

        update_index: null,

        // 의무사항, 위반시 조치 키워드 목록
        duty_list: [],
        penalty_list: [],

        bottom_sheet: {
            add: false,
            update: false
        }
    }),

    mounted(){
        if(this.$store.state.contract.duty_list.length){
            this.list = this.$store.state.contract.duty_list
        }

        // 키워드 불러오기
        this.$http.post("/api/keyword/select")
        .then(res => {
            this.duty_list = res.data.filter(e => e.category_main == '계약' && e.category_sub == '의무사항').map(e => e.text)
            this.penalty_list = res.data.filter(e => e.category_main == '계약' && e.category_sub == '위반시 조치').map(e => e.text)
        })
    },
    
    methods: {
        ...mapMutations(['contract_update_duty_list']),

        // 추가하기
        addItem(){
            this.list.push({
                p1 : this.p1,
                p2 : this.p2,
                p3 : this.p3,
                p4 : this.p4,
                p_memo : this.p_memo,
                v1 : this.v1,
                v2 : this.v2,
                v3 : this.v3,
                v4 : this.v4,
                v_memo : this.v_memo,
                v1_2 : this.v1_2,
                v2_2 : this.v2_2,
                v3_2 : this.v3_2,
                v4_2 : this.v4_2,
                v_memo_2 : this.v_memo_2,
            })

            this.p1 = ''
            this.p2 = ''
            this.p3 = ''
            this.p4 = ''
            this.p_memo = ''

            this.v1 = ''
            this.v2 = ''
            this.v3 = ''
            this.v4 = ''
            this.v_memo = ''

            this.v1_2 = ''
            this.v2_2 = ''
            this.v3_2 = ''
            this.v4_2 = ''
            this.v_memo_2 = ''
        },

        // 수정 열기
        updateOpen(item, index){
            this.update = item
            this.update_index = index
            this.bottom_sheet.update = true
        },

        // 수정 제출하기
        updateSubmit(){
            this.list[this.update_index] = this.update
            this.bottom_sheet.update = false
        },

        // 다음
        submit(){
            console.log(this.list)

            if(!this.list.length){
                this.$globalSnackbar.show("의무사항을 입력해주세요")
            }else{
                console.log(this.list)
                this.contract_update_duty_list(this.list)
                this.$emit('next')
            }
        }
    }
}
</script>