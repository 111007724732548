<template>
    <div>
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">AI 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="ma-2 pa-4 pb-16">
            <p
                class="text-h6 font-weight-medium"
            >
                AI 사용 통계
            </p>


            <table
                class="tableCustom"
            >
                <tr>
                    <th>
                        AI 궁합 보기
                    </th>
                    <td>
                        {{ai_match}}명
                    </td>
                </tr>
                <tr>
                    <th>
                        오늘의 연애운
                    </th>
                    <td>
                        {{ai_fortune}}명
                    </td>
                </tr>
                <tr>
                    <th>
                        오늘의 별자리 운세
                    </th>
                    <td>
                        {{ai_star}}명
                    </td>
                </tr>
                <tr>
                    <th>
                        AI 연애상담
                    </th>
                    <td>
                        {{ai_counseling}}명
                    </td>
                </tr>
                <tr>
                    <th>
                        AI 관상
                    </th>
                    <td>
                        {{ai_face}}명
                    </td>
                </tr>
                <tr>
                    <th>
                        AI 관상 궁합
                    </th>
                    <td>
                        {{ai_face_match}}명
                    </td>
                </tr>
                <tr>
                    <th>
                        AI 관상 매칭
                    </th>
                    <td>
                        {{ai_face_matching}}명
                    </td>
                </tr>
            </table>
        </v-sheet>
    </div>
</template>
<script>
export default {
    data: () => ({
        ai_match: 0,
        ai_fortune: 0,
        ai_star: 0,
        ai_counseling: 0,
        ai_face: 0,
        ai_face_match: 0,
    }),

    mounted(){
        // AI 카운팅 불러오기
        this.load()
    },

    methods: {
        // AI 카운팅 불러오기
        load(){
            this.$http.post('/api/simple/ai_count/select')
            .then((res) => {
                this.ai_match = res.data[0].ai_match
                this.ai_fortune = res.data[0].ai_fortune
                this.ai_star = res.data[0].ai_star
                this.ai_counseling = res.data[0].ai_counseling
                this.ai_face = res.data[0].ai_face
                this.ai_face_match = res.data[0].ai_face_match
                this.ai_face_matching = res.data[0].ai_face_matching
            })
        },
    }
}
</script>
<style scoped>
.tableCustom {
    border: 1px solid #ccc;
    border-collapse: collapse;
}
.tableCustom tr th {
    text-align: left;
    border: 1px solid #ccc;
    width:200px;
    background: #ddebf7;
    padding: 12px;
}

.tableCustom tr td {
    width:300px;
    border: 1px solid #ccc;
    padding: 12px;
}
</style>