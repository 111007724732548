<template>
    <div>
        <v-sheet
            color="#f4f2f1"
            class="px-4 pb-16"
        >
            <div
                class="pt-10"
            >
                <!-- 제목 -->
                <p
                    class="text-h5 font-weight-bold mt-7 mb-2 primary--text"
                >
                    비밀번호 찾기
                </p>

                <!-- 로고 -->
                <v-img
                    style="position:absolute; top:50px; right:40px;"
                    width="80"
                    src="@/assets/logo/default.png"
                ></v-img>

                <!-- 핸드폰 본인인증 -->
                <v-btn
                    block
                    class="mt-12 rounded-10"
                    height="48"
                    large
                    :color="$route.query.status == 'checked'? 'grey lighten-2':'primary'"
                    dark
                    depressed
                    @click="KGMobilians_SendMessage()"
                >
                    핸드폰 본인인증
                </v-btn>

                <!-- 휴대폰 번호 -->
                <p
                    class="text-body-2 mt-10 mb-2 font-weight-medium"
                >
                    휴대폰 번호
                </p>
                <v-text-field
                    outlined
                    style="background:white;"
                    class="rounded-lg"
                    placeholder="휴대폰 번호를 입력해주세요"
                    hide-details
                    v-model="phone"
                    @change="$v.phone.$touch()"
                    @blur="$v.phone.$touch()"
                    :error-messages="phone_Errors"
                    @keyup.enter="submit()"
                ></v-text-field>

                <!-- 새 비밀번호 -->
                <v-text-field
                    outlined
                    style="background:white;"
                    class="rounded-lg mt-8"
                    label="새 비밀번호"
                    type="password"
                    hide-details
                    v-model="password"
                    @change="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                    :error-messages="password_Errors"
                ></v-text-field>

                <!-- 새 비밀번호 조건 -->
                <p class="ml-2 text-caption">
                    *특수문자, 숫자 포함 8자리 이상
                </p>

                <!-- 새 비밀번호 확인 -->
                <v-text-field
                    outlined
                    style="background:white;"
                    class="rounded-lg"
                    label="새 비밀번호 확인"
                    type="password"
                    hide-details
                    v-model="password_check"
                    @change="$v.password_check.$touch()"
                    @blur="$v.password_check.$touch()"
                    :error-messages="password_check_Errors"
                ></v-text-field>

                <!-- 비밀번호 재설정하기 -->
                <v-btn
                    block
                    class="mt-6 rounded-10"
                    height="48"
                    large
                    :color="kg_clicked? 'grey lighten-2':'primary'"
                    dark
                    depressed
                    @click="updatePassword()"
                >
                    비밀번호 재설정하기
                </v-btn>

                <!-- 안내문구 -->
                <p
                    class="text-caption mt-4 mb-2 grey--text text-center"
                >
                    회원님의 비밀번호는 서버에도 보안상 암호화되어 저장됩니다.<br/>
                    휴대폰 본인인증 후 새로운 비밀번호를 설정해주세요
                </p>
            </div>
        </v-sheet>
    </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'
import { mapMutations } from 'vuex'

export default {
    mixins: [validationMixin],

    validations: {
        phone: {
            required,
        },

        password: {
            required,
            minLength: minLength(8)
        },

        password_check: {
            required,
            sameAsPassword: sameAs('password')
        }
    },

    data: () => ({
        phone: "",
        password: "",
        password_check: "",

        // 핸드폰 인증버튼 눌렀었는지
        kg_clicked: false,
    }),

    computed: {
        // 휴대전화 Error 문구
        phone_Errors () {
            const errors = []
            if (!this.$v.phone.$dirty) return errors
            !this.$v.phone.required && errors.push('휴대폰 번호를 입력해주세요')
            return errors
        },

        // 비밀번호 Error 문구
        password_Errors () {
            const errors = []
            if (!this.$v.password.$dirty) return errors
            !this.$v.password.required && errors.push('비밀번호를 입력해주세요')
            !this.$v.password.minLength && errors.push('패스워드는 최소 8자 이상이여야합니다')
            return errors
        },

        // 비밀번호 확인 Error 문구
        password_check_Errors () {
            const errors = []
            if (!this.$v.password_check.$dirty) return errors
            !this.$v.password_check.required && errors.push('비밀번호 확인을 입력해주세요')
            !this.$v.password_check.sameAsPassword && errors.push('비밀번호 확인이 같지 않습니다')
            return errors
        },
    },
    
    methods: {
        ...mapMutations(['login']),

        // 핸드폰 본인인증
        KGMobilians_SendMessage(){
            this.kg_clicked = true
            this.$router.push('/ci_web')
        },

        // 제출하기
        updatePassword: _.debounce(async function() {
            // 입력값 기본 검증
            this.$v.$touch()

            // 입력값 기본 검증 통과 시
            if(!this.$v.$invalid){
                // 본인인증에 이름 있는지 확인
                this.$http.post('/api/simple/signup_check/specific/phone', {
                    params: {
                        phone: this.phone
                    }
                }).then((res) => {
                    if(!res.data){
                        this.$globalSnackbar.show("본인인증을 하지않았거나 입력하신 휴대폰 번호가 본인인증 번호와 다릅니다. ")
                    }else{
                        // 비밀번호 수정
                        this.$http.post("/api/user/update/password", {
                            params: {
                                user_id: this.phone,
                                password_next: this.password
                            }
                        }).then((res) => {
                            if(res.data.affectedRows){
                                this.$globalSnackbar.show("비밀번호가 재설정되었습니다.")
                                this.password = ""
                                this.password_check = ""
                            }
                        })
                    }
                })
            } 
        }, 500)
    }
}
</script>