<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
            class="mx-auto text-center d-flex justify-space-between mt-2"
            style="width:1080px;"
        >
            <div
                style="margin-top:22px;"
            >
                <div
                    class="mt-16 mb-10 d-flex"
                >
                    <div>
                        <v-img
                            width="44"
                            src="@/assets/logo/default.png"
                        ></v-img>
                    </div>
                    <div>
                        <v-img
                            class="mt-3 ml-2"
                            width="80"
                            src="@/assets/landing/text_logo.png"
                        ></v-img>
                    </div>
                </div>

                <div
                    class="d-flex justify-center"
                    style="width:640px;"
                >
                    <v-icon style="margin-bottom:300px;" size="48" color="grey">mdi-format-quote-open-outline</v-icon>
                    <div>
                        <p
                            class="text-h3 font-weight-bold primary--text mb-6 mt-8"
                        >
                            우리는 커플이다
                        </p>

                        <p
                            class="text-h5 font-weight-regular grey--text text--darken-2 mb-14"
                            style="line-height:36px;"
                        >
                            우리 연인이 되었어요! 스윗딜<br/>
                            연인간의 소중한 약속, 이제 계약으로 지키세요.<br/>
                            1분만에 만드는 연인 계약 앱<br/>
                            "스윗딜"
                        </p>

                        <p
                            class="text-h5 font-weight-bold primary--text mb-2"
                        >
                            커플만의 혜택만을 확인하기
                        </p>
                        <v-icon size="24" class="mb-4" color="primary">mdi-chevron-double-down</v-icon>
                    </div>
                    <v-icon style="margin-top:100px;" size="48" color="grey">mdi-format-quote-close-outline</v-icon>
                </div>

                <div
                    class="d-flex justify-center"
                    style="width:640px;"
                >
                    <v-btn
                        color="black"
                        width="220"
                        depressed
                        large
                        class="rounded-15 d-flex py-8 mr-3"
                        @click="link()"
                    >
                        <div
                            style="width:44px;"
                        >
                            <v-img
                                class="mr-3 mb-2px"
                                src="@/assets/landing/apple_white.svg"
                            ></v-img>
                        </div>
                        <p
                            class="mb-0 text-left mr-2 white--text"
                        >
                            App Store에서<br/>
                            <strong>다운받기</strong>
                        </p>
                    </v-btn>

                    <v-btn
                        color="primary"
                        width="220"
                        depressed
                        large
                        class="rounded-15 d-flex py-8 ml-3"
                        @click="linkAndroid()"
                    >
                        <div
                            style="width:48px;"
                        >
                            <v-img
                                class="mr-3"
                                src="@/assets/landing/google.png"
                            ></v-img>
                        </div>
                        <p
                            class="mb-0 text-left mr-1"
                        >
                            Google Play에서<br/>
                            <strong>다운받기</strong>
                        </p>
                    </v-btn>
                </div>
            </div>

            <div
                class="mt-16"
                style="width:360px;"
            >
                <v-img
                    src="@/assets/landing/phone.png"
                ></v-img>
            </div>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
            class="mx-auto text-center"
        >
            <div
                style="margin-top:22px;"
            >
                <div
                    class="mt-2 mb-6 ml-4 d-flex"
                >
                    <div>
                        <v-img
                            width="44"
                            src="@/assets/logo/default.png"
                        ></v-img>
                    </div>
                    <div>
                        <v-img
                            class="mt-3 ml-2"
                            width="80"
                            src="@/assets/landing/text_logo.png"
                        ></v-img>
                    </div>
                </div>

                <div
                    class="d-flex justify-space-between px-6"
                >
                    <v-icon size="32" style="margin-bottom:260px;" color="grey">mdi-format-quote-open-outline</v-icon>
                    <div>
                        <p
                            class="text-h6 font-weight-bold primary--text mb-2 mt-4"
                        >
                            우리는 커플이다
                        </p>

                        <p
                            class="text-subtitle-1 font-weight-regular grey--text text--darken-2 mb-8"
                        >
                            우리 연인이 되었어요! 스윗딜<br/>
                            연인간의 소중한 약속,<br/>
                            이제 계약으로 지키세요.<br/>
                            1분만에 만드는 연인 계약 앱<br/>
                            "스윗딜"
                        </p>

                        <p
                            class="text-subtitle-1 font-weight-bold primary--text mb-2"
                        >
                            커플만의 혜택만을 확인하기
                        </p>
                        <v-icon size="24" class="mb-4" color="primary">mdi-chevron-double-down</v-icon>
                    </div>
                    <v-icon size="32" style="margin-top:80px;" color="grey">mdi-format-quote-close-outline</v-icon>
                </div>

                    <v-btn
                        color="black"
                        width="200"
                        depressed
                        class="rounded-15 d-flex py-8 mx-auto mb-4"
                        @click="link()"
                    >
                        <div
                            style="width:44px;"
                        >
                            <v-img
                                class="mr-3 mb-2px"
                                src="@/assets/landing/apple_white.svg"
                            ></v-img>
                        </div>
                        <p
                            class="mb-0 text-caption text-left mr-2 white--text"
                        >
                            App Store에서<br/>
                            <strong>다운받기</strong>
                        </p>
                    </v-btn>

                    <v-btn
                        color="primary"
                        width="200"
                        depressed
                        class="rounded-15 d-flex py-8 mx-auto"
                        @click="linkAndroid()"
                    >
                        <div
                            style="width:48px;"
                        >
                            <v-img
                                class="mr-3"
                                src="@/assets/landing/google.png"
                            ></v-img>
                        </div>
                        <p
                            class="mb-0 text-caption text-left mr-1"
                        >
                            Google Play에서<br/>
                            <strong>다운받기</strong>
                        </p>
                    </v-btn>
            </div>

            <div
                class="mt-16 mx-auto mb-16"
                style="width:300px;"
            >
                <v-img
                    src="@/assets/landing/phone.png"
                ></v-img>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    data: () => ({
        
    }),

    mounted(){

    },

    methods: {
        link(){
            window.open('https://apps.apple.com/us/app/%EC%8A%A4%EC%9C%97%EB%94%9C-%EC%9A%B0%EB%A6%AC-%EA%B3%84%EC%95%BD%ED%96%88%EC%96%B4%EC%9A%94/id6464309080', '_self')
        },

        linkAndroid(){
            alert("준비중입니다.")
            // window.open('https://play.google.com/store/apps/details?id=com.sweetdeal.sweetdeal_new&hl=ko', '_self')
        }
    }
}
</script> 