<template>
    <div>
        <p
            class="text-h6 font-weight-bold mb-16"
            style="line-height:28px;"
        >
            <span class="primary--text">서명하기</span> (상대방)<br/>
        </p>

        <v-img
            style="position:absolute; top:80px; right:40px;"
            width="80"
            src="@/assets/logo/default.png"
        ></v-img>

        <v-sheet
            outlined
            class="pa-2 rounded-xl"
        >
            <v-sheet
                color="grey lighten-4"
                height="320"
                class="rounded-xl pa-2 d-flex"
                style="position:relative;"
            >
                <vue-drawing-canvas
                    class="mx-auto rounded-xl"
                    width="292"
                    height="304"
                    background-color="transparent"
                    ref="VueCanvasDrawing"
                />
                <p
                    style="padding-top:140px; position:absolute; top:0px; left:50%; transform:translateX(-50%);"
                    class="text-h6 font-weight-bold grey--text text--lighten-1 text-center"
                >
                    서명해주세요
                </p>
            </v-sheet>
        </v-sheet>

        <v-sheet
            color="#f4f2f1"
            class="mt-10"
        >
            <v-btn
                block
                class="rounded-10 primary--text"
                height="48"
                large
                color="grey lighten-2"
                dark
                depressed
                @click="$emit('next')"
            >
                서명 완료하기
            </v-btn>
        </v-sheet>
    </div>
</template>
<script>
import VueDrawingCanvas from "vue-drawing-canvas"

export default {
    components: {
        VueDrawingCanvas,
    },
    
    data: () => ({
        dialog: {
            complete: false
        },

        bottom_sheet: {
            add: false
        },

        p1: "",
        p2: "",
        p3: "",
        p4: "",

        v1: "",
        v2: "",
        v3: "",
        v4: "",

        violation: false,

        p_memo: "",
        v_memo: "",

        list: []
    }),
    
    methods: {
        link(url){
            this.$router.push(url)
        },

        before(){
            this.$router.go(-1)
        },

        test(){
            this.list.push({
                p1 : this.p1,
                p2 : this.p2,
                p3 : this.p3,
                p4 : this.p4,
                p_memo : this.p_memo,
                v1 : this.v1,
                v2 : this.v2,
                v3 : this.v3,
                v4 : this.v4,
                v_memo : this.v_memo,
            })

            this.p1 = ''
            this.p2 = ''
            this.p3 = ''
            this.p4 = ''
            this.p_memo = ''

            this.v1 = ''
            this.v2 = ''
            this.v3 = ''
            this.v4 = ''
            this.v_memo = ''
        }
    }
}
</script>