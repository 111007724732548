<template>
    <div>
        <div>
            <p
                class="text-h6 font-weight-bold mb-16"
                style="line-height:28px;"
            >
                <span class="primary--text">계약서</span><br/>
            </p>

            <v-img
                style="position:absolute; top:80px; right:40px;"
                width="80"
                src="@/assets/logo/default.png"
            ></v-img>

            <v-sheet
                class="mb-8 rounded-10 pt-2 pb-4 px-4"
            >
                <p class="mt-4 mb-4 text-subtitle-1 font-weight-bold" style="line-height:20px;">
                    {{top}}
                </p>
                
                <div
                    v-for="(item, index) in clause_list" :key="index"
                >
                    <p
                        class="mt-10 mb-4 text-subtitle-1 font-weight-bold grey--text text--darken-1"
                        style="line-height:20px;"
                    >
                        제{{index+1}}조 ({{item.title}})
                    </p>
                    <p
                        style="white-space: pre-line;"
                        class="mx-2 font-weight-medium grey--text text--darken-1"
                    >
                        {{item.content}}
                    </p>
                </div>
            </v-sheet>

            <p
                class="text-caption text-center mb-6 grey--text text--darken-1"
            >
                본 계약의 효력은 작성하신 내용 및 관련 법률에 따라 달라질 수 있습니다.
            </p>
        </div>
        <v-sheet
            color="#f4f2f1"
        >
            <v-btn
                block
                class="rounded-10 mb-3"
                height="48"
                large
                color="#fe7f5e"
                dark
                depressed
                @click="dialog.switch = true"
            >
                완료하기
            </v-btn>
        </v-sheet>
        <v-sheet
            color="#f4f2f1"
        >
            <v-btn
                block
                class="rounded-10 mb-3"
                height="48"
                large
                color="primary"
                dark
                depressed
                @click="openInstagram"
            >
                SNS에 계약 체결 사실 공유하기
            </v-btn>
        </v-sheet>
        <p
            class="mt-4 text-center text-caption grey--text text--darken-1 mx-4"
        >
            *SNS에는 애칭으로만 공개되며, 실명 및 생년월일은 공개되지 않습니다.
        </p>

        <!-- 계약서 완료 -->
        <v-dialog
            v-model="dialog.switch"
            width="440"
            content-class="rounded-xl"
        >
            <v-sheet class="px-4 pt-8 pb-6" color="#f8f2f2">
                <v-img
                    class="mx-auto mb-2"
                    width="80"
                    src="@/assets/logo/default.png"
                ></v-img>
            </v-sheet>
            <v-sheet
                class="pt-8 pb-6"
            >
                <p
                    class="mx-10 font-weight-medium mb-0 text-center"
                >
                    <span class="primary--text">{{sender}}</span>님과 <span class="primary--text">{{receiver}}</span>님과의<br/>
                    연인계약을 공개하시겠습니까?
                </p>
                <div class="d-flex justify-center">
                    <v-switch v-model="reveal" label="공개"></v-switch>
                </div>
                
                <p
                    class="mt-0 mb-2 text-center text-caption grey--text text--darken-1 mx-4"
                >
                    *스윗딜에는 애칭으로만 공개되며,<br/>
                    실명 및 생년월일은 공개되지 않습니다.
                </p>

                <!-- 버튼 -->
                <div
                    class="d-flex justify-center"
                >
                    <v-btn
                        class="mt-4 rounded-10 text-body-2"
                        depressed
                        dark
                        color="primary"
                        @click="dialog.finish = true; dialog.switch = false"
                    >
                        계약 완료하기
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>

        <!-- 계약서 완료 -->
        <v-dialog
            v-model="dialog.finish"
            width="440"
            content-class="rounded-xl"
        >
            <v-sheet class="px-4 pt-8 pb-6" color="#f8f2f2">
                <v-img
                    class="mx-auto mb-2"
                    width="80"
                    src="@/assets/logo/default.png"
                ></v-img>
                <p
                    class="grey--text text-center text-caption mb-1"
                >
                    sweetdeal<br/>
                    {{new Date().toLocaleDateString()}}
                </p>
            </v-sheet>
            <v-sheet
                class="pt-8 pb-6"
            >
                <p
                    class="mx-10 font-weight-medium mb-6"
                >
                    <span class="primary--text">{{sender}}</span>님과 <span class="primary--text">{{receiver}}</span>님이<br/>
                    <span class="primary--text">{{new Date().toLocaleDateString()}}</span> 스윗딜을 통해<br/>
                    연인계약을 체결했어요!
                </p>
                <!-- 버튼 -->
                <div
                    class="d-flex justify-center"
                >
                    <v-btn
                        class="mt-4 rounded-10 text-body-2"
                        depressed
                        dark
                        color="primary"
                        @click="finished()"
                    >
                        Home 으로 이동
                    </v-btn>
                </div>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
export default {
    data: () => ({
        contract_id: "",
        sender: "",
        receiver: "",
        top: "",
        clause_list: [],

        reveal: true,

        dialog: {
            switch: false,
            finish: false
        }
    }),

    mounted(){
        // 계약서 정보 가져오기
        this.load()
    },
    
    methods: {
        // 계약서 정보 가져오기
        load(){
            this.$http.post("/api/contract/select/specific", {
                params: {
                    receiver: this.$store.state.sweetdeal_user.name
                }
            }).then((res) => {
                console.log(res)
                this.contract_id = res.data[0].id
                this.sender = res.data[0].sender
                this.receiver = res.data[0].receiver
                this.top = res.data[0].top

                this.$http.post("/api/contract/select/specific/clause_list", {
                    params: {
                        contract_id: this.contract_id
                    }
                }).then((res) => {
                    this.clause_list = res.data
                })
            })
        },

        // 인스타그램 앱 열기
        openInstagram() {
            window.open('https://www.instagram.com/', '_blank');
        },

        // 계약 완료
        finished(){
            this.$http.post("/api/contract/update/status", {
                params: {
                    id: this.contract_id
                }
            }).then((res) => {
                if(res.data.affectedRows)
                {
                    this.$router.push("/")
                }
            })
        }
    }
}
</script>