<template>
    <div
        style="overflow-y:scroll;"
    >
        <!-- 상단 -->
        <v-btn
            style="height:48px;"
            small
            text
            v-ripple="false"
            @click="before()"
        >
            <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <!-- 진행도 -->
        <v-progress-linear
            v-model="progress_line"
            color="primary"
            height="2"
            value="8"
            class="rounded"
        ></v-progress-linear>

        <!-- 본문 -->
        <div
            class="px-4 pt-10 pb-4"
        >
            <!-- 연애시작일 -->
            <!-- <Date
                v-if="progress == 0"
                @next="next()"
            /> -->

            <!-- 본인 -->
            <Me
                v-if="progress == 1"
                @next="next()"
            />

            <!-- 상대방 -->
            <Partner
                v-if="progress == 2"
                @next="next()"
            />

            <!-- 기간 -->
            <Term
                v-if="progress == 3"
                @next="next()"
                class="pb-100px"
            />

            <!-- 계약서 작성 -->
            <Write
                v-if="progress == 4"
                @next="next()"
                class="pb-100px"
            />

            <!-- 계약서 확인 -->
            <WriteCheck
                v-if="progress == 5"
                @next="next()"
                class="pb-100px"
            />

            <!-- 본인 서명 -->
            <SignMe
                v-if="progress == 6"
                @next="next()"
            />

            <!-- 상대방 서명 -->
            <SignPartner
                v-if="progress == 7"
                @next="next()"
            />

            <!-- 완료 -->
            <Final
                v-if="progress == 8"
                class="pb-100px"
            />
        </div>
    </div>
</template>
<script>
import Date from './contract/Date'
import Me from './contract/Me'
import Partner from './contract/Partner'
import Term from './contract/Term'
import Write from './contract/Write'
import WriteCheck from './contract/WriteCheck'
import SignMe from './contract/SignMe'
import SignPartner from './contract/SignPartner'
import Final from './contract/Final'

export default {
    components: {
        Date,
        Me,
        Partner,
        Term,
        Write,
        WriteCheck,
        SignMe,
        SignPartner,
        Final
    },

    data: () => ({
        progress: 1
    }),

    computed: {
        progress_line(){
            return (this.progress / 8) * 100
        }
    },

    mounted(){
        if(!this.$store.state.sweetdeal_user.is_logined){
            this.$globalSnackbar.show("연인 계약서 작성하기는 로그인 이후 이용가능합니다.")
            this.$router.push("/")
        }

        if(this.$route.query.partner){
            this.progress = 7
        }
    },

    methods: {
        before(){
            if(this.progress > 1){
                this.progress--
            }else{
                this.$router.go(-1)
            }
        },

        next(){
            if(this.progress < 8){
                this.progress++
            }
        }
    }
}
</script>
<style scoped>
.v-btn::before {
    background-color: transparent;
}
</style>