<template>
    <div>
        <v-sheet
            color="#f4f2f1"
            class="px-4 pb-16"
        >
            <div
                class="pt-10"
            >
                <!-- 제목 -->
                <p
                    class="text-h5 font-weight-bold mt-7 mb-2 primary--text"
                >
                    로그인
                </p>

                <!-- 로고 -->
                <v-img
                    style="position:absolute; top:50px; right:40px;"
                    width="80"
                    src="@/assets/logo/default.png"
                ></v-img>

                <!-- 휴대폰 번호 -->
                <p
                    class="text-body-2 mt-10 mb-2 font-weight-medium"
                >
                    휴대폰 번호
                </p>
                <v-text-field
                    outlined
                    style="background:white;"
                    class="rounded-lg"
                    placeholder="휴대폰 번호를 입력해주세요"
                    hide-details
                    v-model="user_id"
                    @change="$v.user_id.$touch()"
                    @blur="$v.user_id.$touch()"
                    :error-messages="user_id_Errors"
                    @keyup.enter="submit()"
                ></v-text-field>

                <!-- 비밀번호 -->
                <p
                    class="text-body-2 mt-10 mb-2 font-weight-medium"
                >
                    비밀번호
                </p>
                <v-text-field
                    outlined
                    style="background:white;"
                    class="rounded-lg"
                    placeholder="비밀번호를 입력해주세요"
                    hide-details
                    v-model="password"
                    type="password"
                    @change="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                    :error-messages="password_Errors"
                    @keyup.enter="submit()"
                ></v-text-field>

                <!-- 로그인 버튼 -->
                <v-btn
                    block
                    class="mt-10 rounded-10"
                    height="48"
                    large
                    color="primary"
                    dark
                    depressed
                    @click="submit()"
                >
                    로그인
                </v-btn>

                <!-- 이미 아이디가 있나요? (회원가입 하러 가기) -->
                <p
                    class="text-caption mt-16 mb-2 grey--text text-center"
                >
                    이미 아이디가 있나요?
                </p>
                <v-btn
                    block
                    class="mb-6 rounded-10"
                    height="48"
                    large
                    color="#ffe9ea"
                    dark
                    depressed
                    to="/"
                >
                    <font color="#fea18b">회원가입 하러 가기</font>
                </v-btn>

                <!-- 로그인이 안되시나요? (비밀번호 찾기) -->
                <p
                    class="text-caption mt-4 mb-2 grey--text text-center"
                >
                    로그인이 안되시나요?
                </p>
                <v-btn
                    block
                    class="mb-0 rounded-10"
                    height="48"
                    large
                    color="#ffe9ea"
                    dark
                    depressed
                    to="/auth/find_password"
                >
                    <font color="#fea18b">비밀번호 찾기</font>
                </v-btn>
            </div>
        </v-sheet>
    </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, sameAs } from 'vuelidate/lib/validators'
import { mapMutations } from 'vuex'

export default {
    mixins: [validationMixin],

    validations: {
        user_id: {
            required
        },

        password: {
            required
        }
    },

    data: () => ({
        user_id: "",
        password: ""
    }),

    computed: {
        user_id_Errors () {
            const errors = []
            if (!this.$v.user_id.$dirty) return errors
            !this.$v.user_id.required && errors.push('아이디를 입력해주세요')
            return errors
        },

        password_Errors () {
            const errors = []
            if (!this.$v.password.$dirty) return errors
            !this.$v.password.required && errors.push('비밀번호를 입력해주세요')
            return errors
        }
    },
    
    methods: {
        ...mapMutations(['login']),

        // 로그인
        submit(){
            this.$v.$touch()

            if (!this.$v.$invalid) {
                this.$http.post("/api/user/select/login", {
                    params: {
                        user_id: this.user_id,
                        password: this.password
                    }
                }).then((res) => {
                    if(!res.data[0]){
                        this.$globalSnackbar.show('아이디 또는 비밀번호가 잘못되었습니다.')
                    }else{
                        this.login(res.data[0])
                        this.$router.push("/")
                    }
                })
            }
        }
    }
}
</script>