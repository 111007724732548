<template>
    <div>
        <div>
            <p
                class="text-h6 font-weight-bold mb-16"
                style="line-height:28px;"
            >
                <span class="primary--text">서명하기</span> (본인)<br/>
            </p>

            <v-img
                style="position:absolute; top:80px; right:40px;"
                width="80"
                src="@/assets/logo/default.png"
            ></v-img>

            <v-sheet
                outlined
                class="pa-2 rounded-xl"
            >
                <v-sheet
                    color="grey lighten-4"
                    height="320"
                    class="rounded-xl pa-2 d-flex"
                    style="position:relative;"
                >
                    <vue-drawing-canvas
                        class="mx-auto rounded-xl"
                        width="292"
                        height="304"
                        background-color="transparent"
                        ref="VueCanvasDrawing"
                    />
                    <p
                        style="padding-top:140px; position:absolute; top:0px; left:50%; transform:translateX(-50%);"
                        class="text-h6 font-weight-bold grey--text text--lighten-1 text-center"
                    >
                        서명해주세요
                    </p>
                </v-sheet>
            </v-sheet>
        </div>

        <v-sheet
            color="#f4f2f1"
            class="mt-10"
        >
            <v-btn
                block
                class="rounded-10 primary--text"
                height="48"
                large
                color="grey lighten-2"
                dark
                depressed
                @click="bottom_sheet.kakao_share = true"
            >
                서명 완료하기
            </v-btn>
        </v-sheet>

        <!-- 카카오톡  -->
        <v-bottom-sheet
            inset
            v-model="bottom_sheet.kakao_share"
        >
            <v-sheet
                class="text-center elevation-0 pt-10 pb-14 px-4"
                style="border-top-left-radius:30px; border-top-right-radius:30px; position:relative;"
            >
                <v-icon
                    style="position:absolute; top:16px; left:12px;"
                    size="32"
                    color="grey"
                    @click="bottom_sheet.kakao_share = false"
                >
                    mdi-chevron-left
                </v-icon>
                <p class="mb-4 text-body-2 font-weight-medium">
                    카카오톡에서 공유할 상대를 선택해주세요
                </p>

                <v-btn
                    class="mt-4 rounded-xl"
                    large
                    depressed
                    dark
                    color="primary"
                    outlined
                    @click="submit()"
                >
                    계약 상대에게 공유하기
                </v-btn>
            </v-sheet>
        </v-bottom-sheet>

        <!-- 로딩 -->
        <v-progress-circular
            v-if="loading"
            style="position:absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index:999;"
            indeterminate
            color="primary"
            size="60"
        ></v-progress-circular>
    </div>
</template>
<script>
import VueDrawingCanvas from "vue-drawing-canvas"
import { mapMutations } from 'vuex'

export default {
    components: {
        VueDrawingCanvas
    },

    data: () => ({
        bottom_sheet: {
            kakao_share: false
        },

        loading: false
    }),
    
    methods: {
        ...mapMutations(['resetContract']),

        // 계약서 입력 & 카카오톡 공유
        submit() {
            this.bottom_sheet.kakao_share = false
            this.loading = true

            // 계약서 입력
            this.$http.post("/api/contract/insert", {
                params: {
                    sender: this.$store.state.sweetdeal_user.name,
                    receiver: this.$store.state.contract.partner.name,
                    top: this.$store.state.contract.sheet.top
                }
            }).then(async (res) => {
                // 계약서 조항 입력
                for(let item of this.$store.state.contract.sheet.list){
                    this.$http.post("/api/contract/insert/list", {
                        params: {
                            contract_id: res.data.insertId,
                            title: item.title,
                            content: item.content
                        }
                    })
                }

                // 카카오톡 공유
                Kakao.Share.sendDefault({
                    objectType: "feed",

                    content: {
                        title: '스윗딜 연인계약서',
                        description: '#연인간 새로운 약속',
                        imageUrl: 'https://romainex.com/upload/logo.png',
                        link: {
                            mobileWebUrl: 'https://romainex.com',
                            webUrl: 'https://romainex.com',
                        }
                    },

                    buttons: [
                        {
                            title: '웹으로 보기',
                            link: {
                                mobileWebUrl: 'https://romainex.com',
                                webUrl: 'https://romainex.com',
                            },
                        },
                        {
                            title: '앱으로 보기',
                            link: {
                                androidExecutionParams: 'param1=value1&param2=value2', // 안드로이드 딥 링크 파라미터
                                iosExecutionParams: 'param1=value1&param2=value2', // iOS 딥 링크 파라미터
                            },
                        },
                    ],

                    serverCallbackArgs: {
                        key: 'value', // 사용자 정의 파라미터 설정
                    },
                })

                // 마이페이지 대시보드로 이동 (5초 딜레이 후)
                setTimeout(() => {
                    this.loading = false
                    this.$router.push('/mypage/dashboard')
                }, 5000)
            })
        }
    }
}
</script>