<template>
    <div>
        <!-- 상단 -->
        <div>
            <!-- 제목 -->
            <p
                class="text-h6 font-weight-bold"
                style="line-height:28px;"
            >
                <span class="primary--text">계약 당사자</span><br/>
                (상대방)
            </p>

            <!-- 로고 -->
            <v-img
                style="position:absolute; top:80px; right:40px;"
                width="80"
                src="@/assets/logo/default.png"
            ></v-img>
        </div>

        <!-- 본문 -->
        <div>
            <!-- 이름 -->
            <p class="text-body-2 mt-6 mb-2 font-weight-medium">
                이름
            </p>
            <v-text-field
                outlined
                class="rounded-lg"
                placeholder="이름을 입력해주세요"
                v-model="partner.name"
                @change="$v.partner.name.$touch()"
                @blur="$v.partner.name.$touch()"
                :error-messages="partner_name_Errors"
            ></v-text-field>

            <!-- 애칭 -->
            <p class="text-body-2 mb-2 font-weight-medium">
                애칭
            </p>
            <v-text-field
                outlined
                class="rounded-lg"
                placeholder="애칭을 입력해주세요"
                v-model="partner.love_name"
                @change="$v.partner.love_name.$touch()"
                @blur="$v.partner.love_name.$touch()"
                :error-messages="partner_love_name_Errors"
                counter="8"
            ></v-text-field>

            <!-- 생년월일 -->
            <p class="text-body-2 mb-2 font-weight-medium">
                생년월일
            </p>
            <v-text-field
                outlined
                class="rounded-lg"
                placeholder="생년월일을 입력해주세요 (예: 20020101)"
                v-model="partner.birth"
                @change="$v.partner.birth.$touch()"
                @blur="$v.partner.birth.$touch()"
                :error-messages="partner_birth_Errors"
                counter="8"
            ></v-text-field>

            <!-- 다음 -->
            <v-btn
                block
                class="rounded-10 mt-10"
                height="48"
                large
                color="primary"
                dark
                depressed
                @click="submit()"
            >
                다음
            </v-btn>
        </div>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
    mixins: [validationMixin],

    validations: {
        partner: {
            name: {
                required
            },

            love_name: {
                required,
                maxLength: maxLength(8),
            },

            birth: {
                required,
                numeric,
                minLength: minLength(8),
                maxLength: maxLength(8),
            },
        }
    },

    data: () => ({
        partner: {
            name: "",
            love_name: "",
            birth: ""
        }
    }),


    computed: {
         // 이름 Error 문구
        partner_name_Errors () {
            const errors = []
            if (!this.$v.partner.name.$dirty) return errors
            !this.$v.partner.name.required && errors.push('이름을 입력해주세요')
            return errors
        },

        // 애칭 Error 문구
        partner_love_name_Errors () {
            const errors = []
            if (!this.$v.partner.love_name.$dirty) return errors
            !this.$v.partner.love_name.required && errors.push('애칭을 입력해주세요')
            !this.$v.partner.love_name.maxLength && errors.push('애칭은 8자 이내여야합니다')
            return errors
        },

        // 생년월일 Error 문구
        partner_birth_Errors () {
            const errors = []
            if (!this.$v.partner.birth.$dirty) return errors
            !this.$v.partner.birth.numeric && errors.push('생년월일은 숫자만 입력 가능합니다 (예: 20020101)')
            !this.$v.partner.birth.minLength && errors.push('생년월일은 8자여야합니다 (예: 20020101)')
            !this.$v.partner.birth.maxLength && errors.push('생년월일은 8자여야합니다 (예: 20020101)')
            return errors
        },
    },

    mounted(){
        if(this.$store.state.contract.partner){
            this.partner = this.$store.state.contract.partner
        }
    },

    methods: {
        ...mapMutations(['contract_update_partner']),

        // 다음
        submit(){
            this.$v.$touch()

            if(!this.$v.$invalid){
                this.contract_update_partner(this.partner)
                this.$emit('next')
            }
        }
    }
}
</script>
<style scoped>
/* 입력칸 배경을 하얀색으로 */
::v-deep .v-text-field fieldset {
    background-color: white !important;
}

/* 입력칸 배경을 회색으로 */
::v-deep .readColor fieldset {
    background-color: #efefef !important;
}
</style>