<template>
    <v-sheet
        style="margin:0 auto; position:relative; background:#f4f2f1;"
        class="px-2"
        :width="$vuetify.breakpoint.mobile? '100%':'440px'"
    >
        <!-- 상단 -->
        <v-sheet
            color="#f4f2f1"
            class="px-4 pb-0"
            style="position:relative;"
        >
            <!-- 제목 -->
            <p
                class="text-h6 primary--text font-weight-bold mb-1 pt-6"
                style="line-height:28px;"
            >
                마지노선
            </p>

            <!-- 부제목 -->
            <p
                class="text-subtitle-1 font-weight-bold grey--text text--darken-1"
                style="line-height:28px;"
            >
                나의 절대 양보할 수 없는 가치
            </p>

            <!-- 로고 -->
            <v-img
                style="position:absolute; top:20px; right:40px;"
                width="60"
                src="@/assets/logo/default.png"
            ></v-img>
        </v-sheet>

        <!-- # 본문 -->
        <v-sheet class="pa-4 rounded-10">
            <!-- # 약속사항 -->
            <div>
                <!-- 제목 -->
                <p
                    class="font-weight-bold grey--text text--darken-2 mb-1 text-center"
                >
                    <v-icon small color="primary" class="pb-2px">mdi-chevron-left</v-icon>
                    약속사항
                    <v-icon small color="primary" class="pb-2px">mdi-chevron-right</v-icon>
                </p>

                <v-divider class="mt-2 mb-3"></v-divider>

                <!-- 목록 -->
                <div
                    :key="componentKey"
                >
                    <v-sheet
                        v-for="(item, index) in maginotline_list" :key="index + 'maginotline'"
                        class="mt-2 d-flex"
                    >
                        <!-- 약속 -->
                        <v-text-field
                            v-model="item.maginotline"
                            class="rounded-10 mr-2"
                            style="width:70%;"
                            hide-details
                            dense
                            :placeholder="item.placeholder"
                            outlined
                        ></v-text-field>

                        <!-- 카드 추가/삭제 버튼 -->
                        <v-sheet>
                            <v-btn
                                v-if="!index"
                                height="40"
                                width="40"
                                class="rounded-10"
                                block
                                color="primary"
                                dark
                                depressed
                                @click="addField()"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-btn
                                v-if="index"
                                height="40"
                                width="40"
                                class="rounded-10"
                                block
                                color="primary"
                                dark
                                depressed
                                @click="subtractField(index)"
                            >
                                <v-icon>mdi-minus</v-icon>
                            </v-btn>
                        </v-sheet>
                    </v-sheet>
                </div>
            </div>

            <!-- 상대 이름 -->
            <v-text-field
                style="width:200px;"
                class="mt-6 rounded-10 mx-auto"
                outlined
                dense
                hide-details
                placeholder="상대 이름"
                v-model="name"
            ></v-text-field>

            <v-btn
                width="240"
                class="mt-4 rounded-10 mb-2 d-flex mx-auto"
                color="primary"
                dark
                depressed
                @click="submit()"
            >
                상대에게 보내기
            </v-btn>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        maginotline_list: [],

        name: "",

        componentKey: 0
    }),

    mounted(){
        // 위시항목 예시 목록 불러오기
        this.load_example_list()
    },

    methods: {
        // 배열에서 랜덤하게 n개의 항목을 선택하는 헬퍼 함수
        getRandomItems(array, n) {
            const shuffled = array.sort(() => 0.5 - Math.random())
            return shuffled.slice(0, n)
        },

        // 위시항목 예시 목록 불러오기
        load_example_list(){
            this.$http.post("/api/keyword/select/maginotline")
            .then((res) => {
                console.log(res)

                // res.data에서 랜덤하게 4개 항목 선택
                const randomItems = this.getRandomItems(res.data, 6)
                
                // 선택된 항목을 maginotline_list 형식으로 변환
                this.maginotline_list = randomItems.map(item => ({ placeholder: item.text, maginotline: "" }))

                this.componentKey++
            })
        },

        // 카드 추가
        addField(){
            this.maginotline_list.push(
                {
                    maginotline: "",
                }
            )
        },

        // 카드 빼기
        subtractField(index){
            this.maginotline_list.splice(index,1)
        },

        // 마지노선 입력 & 카카오톡 공유
        submit() {
            if(this.name == ""){
                this.$globalSnackbar.show("상대 이름이 공란입니다")
            }

            if(this.name == this.$store.state.sweetdeal_user.name){
                this.$globalSnackbar.show("자신에게 보낼 수 없습니다")
            }
            // this.loading = true

            // # 마지노선 입력
            this.$http.post("/api/maginotline/insert", {
                params: {
                    sender: this.$store.state.sweetdeal_user.name,
                    receiver: this.name
                }
            }).then(async (res) => {
                // # 마지노선 약속항목 입력
                for(let item of this.maginotline_list){
                    this.$http.post("/api/maginotline/insert/list", {
                        params: {
                            maginotline_id: res.data.insertId,
                            line: item.maginotline,
                        }
                    })
                }

                // 카카오톡 공유
                Kakao.Share.sendDefault({
                    objectType: "feed",

                    content: {
                        title: '스윗딜 마지노선',
                        description: '#나의 절대 양보할 수 없는 가치',
                        imageUrl: 'https://romainex.com/upload/logo.png',
                        link: {
                            mobileWebUrl: 'https://romainex.com',
                            webUrl: 'https://romainex.com',
                        }
                    },

                    buttons: [
                        {
                            title: '웹으로 보기',
                            link: {
                                mobileWebUrl: 'https://romainex.com',
                                webUrl: 'https://romainex.com',
                            },
                        },
                        {
                            title: '앱으로 보기',
                            link: {
                                androidExecutionParams: 'param1=value1&param2=value2', // 안드로이드 딥 링크 파라미터
                                iosExecutionParams: 'param1=value1&param2=value2', // iOS 딥 링크 파라미터
                            },
                        },
                    ],

                    serverCallbackArgs: {
                        key: 'value', // 사용자 정의 파라미터 설정
                    },
                })

                // 마이페이지 대시보드로 이동 (5초 딜레이 후)
                setTimeout(() => {
                    this.loading = false
                    this.$router.push('/mypage/dashboard')
                }, 5000)
            })
        }
    }
}
</script> 