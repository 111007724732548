<template>
    <div>
        <!-- 상단 -->
        <div>
            <!-- 제목 -->
            <p
                class="text-h6 font-weight-bold"
                style="line-height:28px;"
            >
                <span class="primary--text">연애시작일</span>은<br/>
                언제인가요?
            </p>

            <!-- 로고 -->
            <v-img
                style="position:absolute; top:80px; right:40px;"
                width="80"
                src="@/assets/logo/default.png"
            ></v-img>
        </div>

        <!-- 본문 -->
        <div>
            <!-- 연애시작일 -->
            <p class="text-body-2 mt-6 mb-2 font-weight-medium">
                연애시작일
            </p>
            <v-text-field
                outlined
                class="rounded-lg"
                placeholder="연애시작일을 입력해주세요 (예: 20230101)"
                v-model="love_start"
                @change="$v.love_start.$touch()"
                @blur="$v.love_start.$touch()"
                :error-messages="love_start_Errors"
                counter="8"
            ></v-text-field>

            <!-- 다음 -->
            <v-btn
                block
                class="rounded-10 mt-10"
                height="48"
                large
                color="primary"
                dark
                depressed
                @click="submit()"
            >
                다음
            </v-btn>
        </div>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
    mixins: [validationMixin],

    validations: {
        love_start: {
            required,
            numeric,
            minLength: minLength(8),
            maxLength: maxLength(8),
        }
    },

    data: () => ({
        love_start: ""
    }),


    computed: {
        // 연애시작일 Error 문구
        love_start_Errors () {
            const errors = []
            if (!this.$v.love_start.$dirty) return errors
            !this.$v.love_start.required && errors.push('연애시작일을 입력해주세요')
            !this.$v.love_start.numeric && errors.push('연애시작일은 숫자만 입력 가능합니다 (예: 20230101)')
            !this.$v.love_start.minLength && errors.push('연애시작일은 8자여야합니다 (예: 20230101)')
            !this.$v.love_start.maxLength && errors.push('연애시작일은 8자여야합니다 (예: 20230101)')
            return errors
        }
    },

    mounted(){
        if(this.$store.state.contract.love_start){
            this.love_start = this.$store.state.contract.love_start
        }
    },

    methods: {
        ...mapMutations(['contract_update_love_start']),

        // 다음
        submit(){
            this.$v.$touch()

            if(!this.$v.$invalid){
                this.contract_update_love_start(this.love_start)
                this.$emit('next')
            }
        }
    }
}
</script>
<style scoped>
/* 입력칸 배경을 하얀색으로 */
::v-deep .v-text-field fieldset {
    background-color: white !important;
}
</style>