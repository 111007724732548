<template>
    <div>
        <!-- 제목 -->
        <v-sheet
            outlined
            class="d-flex align-center ma-2 px-4"
            height="60"
        >
            <font class="font-weight-bold text-h6 grey--text text--darken-2">위시리스트 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="ma-2 pa-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-4">
                <v-sheet class="d-flex" width="660">
                    <!-- 유형 -->
                    <v-sheet width="200" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="primary"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-radio-group v-model="selected" hide-details>
                <v-data-table
                    hide-default-header
                    :items="list"
                    :footer-props="{
                        'items-per-page-options': [8, 20, 100, -1],
                        'itemsPerPageText': '페이지당 위시리스트 수'
                    }"
                    no-data-text=""
                >
                    <template v-slot:header>
                        <tr>
                            <th style="width:52px;"></th>
                            <th style="width:160px">위시리스트 발송인</th>
                            <th style="width:160px">위시리스트 수신인</th>
                            <th style="width:500px">위시리스트 내용</th>
                            <th style="width:140px">위시리스트 상태</th>
                            <th style="width:140px">위시리스트 공개 유무</th>
                            <th style="width:200px">위시리스트 일자</th>
                        </tr>
                    </template>
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                <v-radio
                                    class="ml-1"
                                    hide-details
                                    dense
                                    :value="item"
                                    @change="selectedItem = item"
                                ></v-radio>
                            </td>
                            <td><font class="d-block text-truncate" style="width:160px;">{{item.sender}}</font></td>
                            <td><font class="d-block text-truncate" style="width:160px;">{{item.receiver}}</font></td>
                            <td><font class="d-block text-truncate" style="width:500px">{{item.wishlist_list}}</font></td>
                            <td><font class="d-block text-truncate" style="width:140px;">{{item.status}}</font></td>
                            <td><font class="d-block text-truncate" style="width:140px;">{{item.reveal}}</font></td>
                            <td><font class="d-block text-truncate" style="width:200px;">{{new Date(item.created).toLocaleString()}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <!-- <v-btn
                    class="px-6 ml-3"
                    color="red"
                    large
                    dark
                    depressed
                    @click="viewItem()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    상세보기
                </v-btn> -->

                <v-btn
                    class="px-6 ml-3"
                    color="red"
                    large
                    dark
                    depressed
                    @click="deleteItem()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    삭제하기
                </v-btn>
            </v-card>
        </v-sheet>

        <!-- <v-dialog
            v-model="dialog.viewer"
            width="680"
            content-class="rounded-10"
        >
            <ContractViewer
                :id="selectedItem.id"
                :key="componentKey"
            />
        </v-dialog> -->
    </div>
</template>
<script>
import ContractViewer from "@/components/ContractViewer"

export default {
    components: {
        ContractViewer
    },

    data: () => ({
        select_list: {
            search_type: [
                "위시리스트 발송인",
                "위시리스트 수신인",
                "위시리스트 내용",
            ]
        },

        list: [],

        selected: false,
        selectedItem: {},

        search_type: "위시리스트 발송인",
        keyword: "",

        dialog: {
            viewer: false
        },

        componentKey: 0
    }),

    mounted(){
        // 검색
        this.search()
    },

    methods: {
        // 검색
        search(){
            this.$http.post('/api/wishlist/select/search', {
                params: {
                    search_type: this.search_type,
                    keyword: this.keyword
                }
            }).then((res) => {
                this.list = res.data
            })
        },

        // 상세보기
        viewItem(){
            if(!Object.keys(this.selectedItem).length){
                this.$globalSnackbar.show("선택한 위시리스트가 없습니다")
            }else{
                this.componentKey++
                this.dialog.viewer = true
            }
        },

        // 삭제하기
        deleteItem(){
            if(!Object.keys(this.selectedItem).length){
                this.$globalSnackbar.show("선택한 위시리스트가 없습니다")
            }else{
                if(confirm("정말 해당 위시리스트를 삭제하겠습니까?")){
                    this.$http.post('/api/wishlist/delete', {
                        params: {
                            id: this.selectedItem.id
                        }
                    }).then((res) => {
                        if(res.data.affectedRows){
                            this.$globalSnackbar.show("해당 위시리스트가 삭제되었습니다.")
                            this.search()
                        }
                    })
                }
            }
        }
    }
}
</script>