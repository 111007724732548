<template>
    <div>
        <!-- 상단 -->
        <div>
            <!-- 제목 -->
            <p
                class="text-h6 font-weight-bold"
                style="line-height:28px;"
            >
                <span class="primary--text">계약 당사자</span><br/>
                (본인)
            </p>

            <!-- 로고 -->
            <v-img
                style="position:absolute; top:80px; right:40px;"
                width="80"
                src="@/assets/logo/default.png"
            ></v-img>
        </div>

        <!-- 본문 -->
        <div>
            <!-- 이름 -->
            <p class="text-body-2 mt-6 mb-2 font-weight-medium">
                이름
            </p>
            <v-text-field
                outlined
                class="rounded-lg readColor"
                hide-details
                readonly
                v-model="$store.state.sweetdeal_user.name"
            ></v-text-field>

            <!-- 애칭 -->
            <p class="text-body-2 mt-6 mb-2 font-weight-medium">
                애칭
            </p>
            <v-text-field
                outlined
                class="rounded-lg"
                placeholder="애칭을 입력해주세요"
                v-model="my_love_name"
                @change="$v.my_love_name.$touch()"
                @blur="$v.my_love_name.$touch()"
                :error-messages="my_love_name_Errors"
            ></v-text-field>

            <!-- 생년월일 -->
            <p class="text-body-2 mb-2 font-weight-medium">
                생년월일
            </p>
            <v-text-field
                outlined
                class="rounded-lg readColor"
                hide-details
                readonly
                v-model="$store.state.sweetdeal_user.birth"
            ></v-text-field>

            <!-- 다음 -->
            <v-btn
                block
                class="rounded-10 mt-10"
                height="48"
                large
                color="primary"
                dark
                depressed
                @click="submit()"
            >
                다음
            </v-btn>
        </div>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
    mixins: [validationMixin],

    validations: {
        my_love_name: {
            required,
            maxLength: maxLength(8),
        }
    },

    data: () => ({
        my_love_name: ""
    }),


    computed: {
        // 애칭 Error 문구
        my_love_name_Errors () {
            const errors = []
            if (!this.$v.my_love_name.$dirty) return errors
            !this.$v.my_love_name.required && errors.push('애칭을 입력해주세요')
            !this.$v.my_love_name.maxLength && errors.push('애칭은 8자 이내여야합니다')
            return errors
        }
    },

    mounted(){
        if(this.$store.state.contract.my.love_name){
            this.my_love_name = this.$store.state.contract.my.love_name
        }
    },

    methods: {
        ...mapMutations(['contract_update_my']),

        // 다음
        submit(){
            this.$v.$touch()

            if(!this.$v.$invalid){
                let payload = {
                    name : this.$store.state.sweetdeal_user.name,
                    love_name : this.my_love_name,
                    birth : this.$store.state.sweetdeal_user.birth,
                }

                this.contract_update_my(payload)
                this.$emit('next')
            }
        }
    }
}
</script>
<style scoped>
/* 입력칸 배경을 하얀색으로 */
::v-deep .v-text-field fieldset {
    background-color: white !important;
}

/* 입력칸 배경을 회색으로 */
::v-deep .readColor fieldset {
    background-color: #efefef !important;
}
</style>