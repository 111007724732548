<template>
    <div
        class="mx-auto pt-4 pb-4 px-0"
    >
        <v-row>
            <v-col
                v-for="item in list" :key="item.id"
                cols="12"
            >
                <v-card
                    class="mb-0 rounded-10"
                    style="cursor:pointer; box-shadow: 0px 4px 16px #ddd;"
                    @click="read(item)"
                >
                    <!-- 썸네일 -->
                    <v-img
                        height="220"
                        style="border-radius: 10px 10px 0 0;"
                        :src="item.image != 'default'? item.image : require('@/assets/board/thumbnail.png')"
                    ></v-img>

                    <!-- 텍스트 -->
                    <v-sheet class="pt-4 pl-5 pr-6 pb-2">
                        <div
                            class="d-flex justify-space-between"
                            style="position:relative;"
                        >
                            <v-sheet width="240" color="transparent">
                                <!-- 제목 -->
                                <div
                                    class="text-subtitle-1 font-weight-medium mb-2 pr-2 grey--text text--darken-2"
                                    style="white-space:pre-line; width:240px; overflow:hidden; word-wrap:break-word; line-height:20px;"
                                >
                                    {{item.title}}
                                </div>
                            </v-sheet>
                        </div>
                    </v-sheet>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    data: () => ({
        tabs: null,
        category_list: [],
        list: [],

        page: 1,
        pageCount: 1,
        itemsPerPage: 12,

        share_id: "",
        share_nickname: "",
        dialog: {
            share: false
        },

        canWrite: false,

        user: {}
    }),

    mounted(){
        // 전체 게시글 불러오기
        this.loadArticle()
    },

    methods: {
        // 게시글 목록 불러오기
        loadArticle(){
            this.$http.post("/api/event/select", {
            }).then((res) => {
                this.list = res.data

                // 썸네일 추가
                this.matchThumbnail(this.list)
            })
        },

        // 썸네일 추가
        matchThumbnail(list) {
            // <img> 태그용 정규식
            const imgReg = /<img[^>]*src=["']?([^>"']+)["']?[^>]*>/i;
            // CustomImage 태그용 정규식 (대소문자 구분 없음)
            const customImgReg = /<customimage[^>]*src=["']?([^>"']+)["']?[^>]*>/i;

            list.forEach(e => {
                if (typeof e.content === 'string') {
                    const imgMatch = e.content.match(imgReg);
                    const customImgMatch = e.content.match(customImgReg);

                    if (customImgMatch) {
                        // CustomImage 태그에서 src 추출
                        e.image = customImgMatch[0].replace(/.*src="([^"]*)".*/, '$1')
                    }
                    else if (imgMatch) {
                        // <img> 태그에서 src 추출
                        e.image = imgMatch[0].replace(/.*src="([^"]*)".*/, '$1')
                    }
                }
            });
        },

        // 읽기로 이동
        read(item){
            this.$router.push("/read?id="+item.id)
        },

        // 공유하기 열기
        share_open(item){
            if(!this.$store.state.hyundai_user.is_logined){
                alert("게시글 공유는 로그인 후 이용가능합니다.")
            }else{
                this.share_id = item.id
                this.dialog.share = true
            }
        }
    }
}
</script>
<style scoped>
/* Custom Truncate */
.custom-truncate-mobile{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>

<style>
.v-tab--active {
    background-color: white;
}
.v-pagination__item {
    color: #333 !important;
}

.theme--light.v-tabs .v-tab--active:hover::before, .theme--light.v-tabs .v-tab--active::before {
    opacity: 0 !important
}
.theme--light.v-tabs .v-tab:hover::before {
    opacity: 0 !important
}
.v-tab:before {
    color:white;
}
</style>